define('frontend/components/publication-field-preview', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    isTypeLinks: _ember['default'].computed.equal('type', 'links'),
    isTypeList: _ember['default'].computed.equal('type', 'list'),
    isTypeAuthors: _ember['default'].computed.equal('type', 'authors'),

    isTypeIdentifier: _ember['default'].computed.equal('type', 'identifier'),
    getCorrectBaseURL: function getCorrectBaseURL(listItem, listLabel) {
      var baseURLS = {
        DOI: 'https://doi.org/',
        handle: 'http://hdl.handle.net/',
        libris: 'http://libris.kb.se/bib/',
        pubmed: 'https://www.ncbi.nlm.nih.gov/pubmed/'
      };

      var isPubmed = function isPubmed(item) {
        return item.indexOf('Pubmed-ID') !== -1;
      };
      var isDOI = function isDOI(item) {
        return item.indexOf('DOI') !== -1;
      };
      var isHandle = function isHandle(item) {
        return item.indexOf('Handle-ID') !== -1;
      };
      var isLibris = function isLibris(item) {
        return item.indexOf('Libris-ID') !== -1;
      };

      if (isPubmed(listItem[listLabel])) {
        return baseURLS.pubmed;
      }
      if (isDOI(listItem[listLabel])) {
        return baseURLS.DOI;
      }
      if (isHandle(listItem[listLabel])) {
        return baseURLS.handle;
      }
      if (isLibris(listItem[listLabel])) {
        return baseURLS.libris;
      }
      return null;
    },

    listValueArray: _ember['default'].computed('fieldValue', 'listLabel', 'listValue', function () {
      var listValue = this.get('listValue');
      var listLabel = this.get('listLabel');

      if (this.get('isTypeIdentifier')) {
        var that = this;
        return this.get('fieldValue').map(function (listItem) {
          var baseURL = that.getCorrectBaseURL(listItem, listLabel);
          if (baseURL !== null) {
            return _ember['default'].String.htmlSafe(listItem[listLabel] + ': ' + "<a target='_blank' href='" + baseURL + listItem[listValue] + "'>" + baseURL + listItem[listValue] + "</a>");
          } else {
            return listItem[listLabel] + ': ' + listItem[listValue];
          }
        });
      } else {
        return this.get('fieldValue').map(function (listItem) {
          if (listLabel) {
            return listItem[listLabel] + ': ' + listItem[listValue];
          } else {
            return listItem[listValue];
          }
        });
      }
    })
  });
});