define("frontend/templates/components/field-component", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 7,
                "column": 4
              }
            },
            "moduleName": "frontend/templates/components/field-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "error-feedback", [["get", "errors", ["loc", [null, [6, 23], [6, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 6], [6, 31]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 4
              },
              "end": {
                "line": 11,
                "column": 4
              }
            },
            "moduleName": "frontend/templates/components/field-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "text-danger");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["components.fieldComponent.mandatory"], [], ["loc", [null, [10, 32], [10, 75]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 4
              },
              "end": {
                "line": 14,
                "column": 4
              }
            },
            "moduleName": "frontend/templates/components/field-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "style", "color:#bbbb00");
            dom.setAttribute(el1, "class", "fa fa-exclamation-triangle");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 2
              },
              "end": {
                "line": 18,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/components/field-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "help-block");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'id');
            morphs[1] = dom.createMorphAt(element4, 0, 0);
            return morphs;
          },
          statements: [["attribute", "id", ["get", "GUID", ["loc", [null, [17, 15], [17, 19]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "linkify", [["subexpr", "t", [["get", "fieldHelptext", ["loc", [null, [17, 54], [17, 67]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 51], [17, 68]]], 0, 0]], [], ["loc", [null, [17, 41], [17, 70]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 6
                },
                "end": {
                  "line": 23,
                  "column": 6
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "error", ["loc", [null, [22, 8], [22, 17]]], 0, 0, 0, 0]],
            locals: ["error"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 2
              },
              "end": {
                "line": 25,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/components/field-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "text-danger");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "errors", ["loc", [null, [21, 14], [21, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [21, 6], [23, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 2
              },
              "end": {
                "line": 28,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/components/field-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "input", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [27, 62], [27, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [27, 77], [27, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "concat", ["form-control", " ", ["subexpr", "if", [["get", "fieldName", [], 0, 0, 0, 0], ["subexpr", "-normalize-class", ["fieldName", ["get", "fieldName", [], 0, 0, 0, 0]], [], [], 0, 0]], [], [], 0, 0], " "], [], [], 0, 0]], ["loc", [null, [27, 6], [27, 84]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 4
                },
                "end": {
                  "line": 31,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "input", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [30, 62], [30, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [30, 77], [30, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "concat", ["form-control", " ", ["subexpr", "if", [["get", "fieldName", [], 0, 0, 0, 0], ["subexpr", "-normalize-class", ["fieldName", ["get", "fieldName", [], 0, 0, 0, 0]], [], [], 0, 0]], [], [], 0, 0], " "], [], [], 0, 0]], ["loc", [null, [30, 6], [30, 84]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 4
                },
                "end": {
                  "line": 34,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [33, 59], [33, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "concat", ["form-control", " ", ["subexpr", "if", [["get", "fieldName", [], 0, 0, 0, 0], ["subexpr", "-normalize-class", ["fieldName", ["get", "fieldName", [], 0, 0, 0, 0]], [], [], 0, 0]], [], [], 0, 0], " "], [], [], 0, 0]], ["loc", [null, [33, 6], [33, 66]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 4
                },
                "end": {
                  "line": 37,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "pikaday-input", [], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [36, 28], [36, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control fieldName", "format", "YYYY-MM-DD", "useUTC", true, "allowBlank", true, "firstDay", 1, "placeholder", "YYYY-MM-DD", "onSelection", ["subexpr", "action", [["subexpr", "mut", [["get", "value", ["loc", [null, [36, 174], [36, 179]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 169], [36, 180]]], 0, 0]], [], ["loc", [null, [36, 161], [36, 181]]], 0, 0]], ["loc", [null, [36, 6], [36, 183]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 4
                },
                "end": {
                  "line": 40,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [39, 62], [39, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "concat", ["form-control", " ", ["subexpr", "if", [["get", "fieldName", [], 0, 0, 0, 0], ["subexpr", "-normalize-class", ["fieldName", ["get", "fieldName", [], 0, 0, 0, 0]], [], [], 0, 0]], [], [], 0, 0], " "], [], [], 0, 0]], ["loc", [null, [39, 6], [39, 69]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 4
                },
                "end": {
                  "line": 43,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "category-selector", [], ["categoryList", ["subexpr", "@mut", [["get", "value", ["loc", [null, [42, 39], [42, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "categoryObjects", ["subexpr", "@mut", [["get", "categoryObjects", ["loc", [null, [42, 61], [42, 76]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [42, 6], [42, 78]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 4
                },
                "end": {
                  "line": 53,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "select-2", [], ["multiple", true, "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [47, 16], [47, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "selectValues", ["loc", [null, [48, 18], [48, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelSelectedPath", "title", "optionLabelPath", "title", "optionDescriptionPath", "null"], ["loc", [null, [45, 6], [52, 8]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 56,
                        "column": 20
                      },
                      "end": {
                        "line": 56,
                        "column": 43
                      }
                    },
                    "moduleName": "frontend/templates/components/field-component.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "prompt", ["loc", [null, [56, 33], [56, 43]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 56,
                      "column": 6
                    },
                    "end": {
                      "line": 56,
                      "column": 56
                    }
                  },
                  "moduleName": "frontend/templates/components/field-component.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "x-option", [], [], 0, null, ["loc", [null, [56, 20], [56, 56]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 58,
                        "column": 10
                      },
                      "end": {
                        "line": 58,
                        "column": 68
                      }
                    },
                    "moduleName": "frontend/templates/components/field-component.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "selectValue.label", ["loc", [null, [58, 47], [58, 68]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 57,
                      "column": 8
                    },
                    "end": {
                      "line": 59,
                      "column": 8
                    }
                  },
                  "moduleName": "frontend/templates/components/field-component.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "x-option", [], ["value", ["subexpr", "@mut", [["get", "selectValue.value", ["loc", [null, [58, 28], [58, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [58, 10], [58, 81]]]]],
                locals: ["selectValue"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 6
                  },
                  "end": {
                    "line": 60,
                    "column": 6
                  }
                },
                "moduleName": "frontend/templates/components/field-component.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "prompt", ["loc", [null, [56, 12], [56, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [56, 6], [56, 63]]]], ["block", "each", [["get", "selectValues", ["loc", [null, [57, 16], [57, 28]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [57, 8], [59, 17]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 4
                },
                "end": {
                  "line": 61,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "x-select", [], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [55, 24], [55, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control fieldName"], 0, null, ["loc", [null, [55, 6], [60, 19]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child7 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 4
                },
                "end": {
                  "line": 70,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "form-inline");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "checkbox");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1, 1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element3, 1, 1);
              morphs[1] = dom.createMorphAt(element3, 3, 3);
              return morphs;
            },
            statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "value", ["loc", [null, [66, 44], [66, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "fieldName", ["loc", [null, [66, 56], [66, 65]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [66, 12], [66, 67]]], 0, 0], ["inline", "t", ["components.fieldComponent.aheadCheckbox"], [], ["loc", [null, [66, 68], [66, 115]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child8 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 4
                },
                "end": {
                  "line": 79,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "form-inline");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "checkbox");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1, 1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element2, 1, 1);
              morphs[1] = dom.createMorphAt(element2, 3, 3);
              return morphs;
            },
            statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "value", ["loc", [null, [75, 44], [75, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "fieldName", ["loc", [null, [75, 56], [75, 65]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [75, 12], [75, 67]]], 0, 0], ["inline", "t", ["components.fieldComponent.artisticCheckbox"], [], ["loc", [null, [75, 68], [75, 118]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child9 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 4
                },
                "end": {
                  "line": 88,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "form-inline");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "checkbox");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element1, 1, 1);
              morphs[1] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "value", ["loc", [null, [84, 44], [84, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "@mut", [["get", "fieldName", ["loc", [null, [84, 56], [84, 65]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [84, 12], [84, 67]]], 0, 0], ["inline", "t", ["components.fieldComponent.refCheckbox"], [], ["loc", [null, [84, 68], [84, 113]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child10 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 92,
                    "column": 8
                  },
                  "end": {
                    "line": 92,
                    "column": 146
                  }
                },
                "moduleName": "frontend/templates/components/field-component.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", ["components.fieldComponent.radioLabel1"], [], ["loc", [null, [92, 101], [92, 146]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 95,
                    "column": 8
                  },
                  "end": {
                    "line": 95,
                    "column": 147
                  }
                },
                "moduleName": "frontend/templates/components/field-component.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", ["components.fieldComponent.radioLabel2"], [], ["loc", [null, [95, 102], [95, 147]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 97,
                    "column": 6
                  },
                  "end": {
                    "line": 111,
                    "column": 6
                  }
                },
                "moduleName": "frontend/templates/components/field-component.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "select-2", [], ["placeholder", ["subexpr", "t", [["get", "fieldPlaceholder", ["loc", [null, [99, 25], [99, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [99, 22], [99, 42]]], 0, 0], "value", ["subexpr", "@mut", [["get", "selectedJournal", ["loc", [null, [100, 16], [100, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "typeaheadSearchingText", "Searching journals", "typeaheadNoMatchesText", "No journals found for '%@'", "typeaheadErrorText", "Loading failed: %@", "minimumInputLength", 3, "maximumInputLength", 100, "optionDescriptionPath", "issn", "optionLabelSelectedPath", "title", "optionLabelPath", "title", "query", "queryJournals"], ["loc", [null, [98, 6], [110, 8]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 111,
                    "column": 6
                  },
                  "end": {
                    "line": 113,
                    "column": 6
                  }
                },
                "moduleName": "frontend/templates/components/field-component.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "input", [], ["type", "text", "placeholder", ["subexpr", "t", [["get", "fieldPlaceholder", ["loc", [null, [112, 43], [112, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [112, 40], [112, 60]]], 0, 0], "class", "form-control fieldName", "value", ["subexpr", "@mut", [["get", "sourcetitle", ["loc", [null, [112, 98], [112, 109]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [112, 8], [112, 111]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 4
                },
                "end": {
                  "line": 115,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/field-component.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "form-group");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "radio-inline");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "radio-inline");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(element0, 5, 5);
              return morphs;
            },
            statements: [["block", "radio-button", [], ["value", "journal", "groupValue", ["subexpr", "@mut", [["get", "sourceTitleType", ["loc", [null, [92, 51], [92, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "changed", "sourceTitleTypeChanged"], 0, null, ["loc", [null, [92, 8], [92, 163]]]], ["block", "radio-button", [], ["value", "freetext", "groupValue", ["subexpr", "@mut", [["get", "sourceTitleType", ["loc", [null, [95, 52], [95, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "changed", "sourceTitleTypeChanged"], 1, null, ["loc", [null, [95, 8], [95, 164]]]], ["block", "if", [["get", "sourceTitleTypeJournal", ["loc", [null, [97, 12], [97, 34]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [97, 6], [113, 13]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 2
              },
              "end": {
                "line": 116,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/components/field-component.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(11);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
            morphs[7] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[8] = dom.createMorphAt(fragment, 8, 8, contextualElement);
            morphs[9] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            morphs[10] = dom.createMorphAt(fragment, 10, 10, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isTypeISSN", ["loc", [null, [29, 10], [29, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [29, 4], [31, 11]]]], ["block", "if", [["get", "isTypeISBN", ["loc", [null, [32, 10], [32, 20]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [32, 4], [34, 11]]]], ["block", "if", [["get", "isTypeDate", ["loc", [null, [35, 10], [35, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [35, 4], [37, 11]]]], ["block", "if", [["get", "isTypeTextarea", ["loc", [null, [38, 10], [38, 24]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [38, 4], [40, 11]]]], ["block", "if", [["get", "isTypeCategorySelector", ["loc", [null, [41, 10], [41, 32]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [41, 4], [43, 11]]]], ["block", "if", [["get", "isTypeMultiSelect", ["loc", [null, [44, 10], [44, 27]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [44, 4], [53, 11]]]], ["block", "if", [["get", "isTypeSelect", ["loc", [null, [54, 10], [54, 22]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [54, 4], [61, 11]]]], ["block", "if", [["get", "isTypeAheadOfPrint", ["loc", [null, [62, 10], [62, 28]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [62, 4], [70, 11]]]], ["block", "if", [["get", "isTypeArtistic", ["loc", [null, [71, 10], [71, 24]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [71, 4], [79, 11]]]], ["block", "if", [["get", "isTypeRef", ["loc", [null, [80, 10], [80, 19]]], 0, 0, 0, 0]], [], 9, null, ["loc", [null, [80, 4], [88, 11]]]], ["block", "if", [["get", "isTypeJournal", ["loc", [null, [89, 10], [89, 23]]], 0, 0, 0, 0]], [], 10, null, ["loc", [null, [89, 4], [115, 11]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 117,
              "column": 0
            }
          },
          "moduleName": "frontend/templates/components/field-component.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "class", "control-label");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(element5, 1, 1);
          morphs[1] = dom.createMorphAt(element5, 3, 3);
          morphs[2] = dom.createMorphAt(element5, 5, 5);
          morphs[3] = dom.createMorphAt(element5, 6, 6);
          morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[6] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "errorsold", ["loc", [null, [5, 10], [5, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 4], [7, 11]]]], ["content", "getLabel", ["loc", [null, [8, 4], [8, 16]]], 0, 0, 0, 0], ["block", "if", [["get", "isMandatory", ["loc", [null, [9, 10], [9, 21]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [9, 4], [11, 11]]]], ["block", "if", [["get", "showWarningSymbol", ["loc", [null, [12, 10], [12, 27]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [12, 4], [14, 11]]]], ["block", "if", [["get", "fieldHelptext", ["loc", [null, [16, 8], [16, 21]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [16, 2], [18, 9]]]], ["block", "if", [["get", "errors", ["loc", [null, [19, 8], [19, 14]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [19, 2], [25, 9]]]], ["block", "if", [["get", "isTypeText", ["loc", [null, [26, 8], [26, 18]]], 0, 0, 0, 0]], [], 5, 6, ["loc", [null, [26, 2], [116, 9]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 118,
            "column": 0
          }
        },
        "moduleName": "frontend/templates/components/field-component.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" S_YES-READY ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" T_NOT-READY (linkify) ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isVisible", ["loc", [null, [3, 6], [3, 15]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [117, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});