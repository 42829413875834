define('frontend/components/highlightable-element', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    scroller: _ember['default'].inject.service(),

    isHighlighted: false,

    didInsertElement: function didInsertElement() {
      //Class binding with computed property instead?
      if (this.get('isHighlighted')) {
        this.get("scroller").scrollVertical('#' + this.get("element.id"), { duration: 300 });
        _ember['default'].$(this.element).addClass('success').fadeTo(500, 0.25).fadeTo(500, 1, function () {
          var _this = this;

          _ember['default'].run.later(function () {
            _ember['default'].$(_this).removeClass('success');
          }, 5000);
        });
      }
    }
  });
});