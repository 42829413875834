define('frontend/controllers/publications/dashboard/manage/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    manageController: _ember['default'].inject.controller('publications.dashboard.manage'),
    selectedSource: null,
    sourceId: null,
    error: null,
    importData: null,

    idPlaceholderString: _ember['default'].computed('selectedSource', function () {
      var prefix = this.get('i18n').t('publications.dashboard.manage.new.importPub.form.inputId.placeholder');
      switch (this.get('selectedSource')) {
        case 'gupea':
          prefix += '12345';
          break;
        case 'pubmed':
          prefix += '25855245';
          break;
        case 'scopus':
          prefix += '10.1577/H02-043';
          break;
        case 'libris':
          prefix += '978-91-7385-325-5';
          break;
        default:
          prefix = 'ID';
          break;
      }
      return prefix;
    }),

    fetchButtonIsActive: _ember['default'].computed('selectedSource', 'sourceId', function () {
      return this.get('selectedSource') && this.get('sourceId');
    }),

    actions: {
      fetchSource: function fetchSource() {
        var _this = this;

        this.set('error', null);
        var successHandler = function successHandler(model) {
          _this.set('importData', model);
        };
        var errorHandler = function errorHandler(error) {
          _this.set('error', error.error);
          _this.set('importData', null);
        };
        var setImportData = this.store.save('import_data', { datasource: this.get('selectedSource'), sourceid: this.get('sourceId') }).then(function (model) {
          if (model.error) {
            errorHandler(model);
          } else {
            successHandler(model);
          }
        }, errorHandler);
        this.send('pageIsDisabled', setImportData);
      },
      createPublication: function createPublication(model) {
        var _this2 = this;

        if (!model) {
          model = {};
        }
        var saveAndEditDraft = new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _this2.store.save('draft', model).then(function (model) {
            _this2.transitionToRoute('publications.dashboard.manage.show.edit', model.id).then(resolve, reject);
          }, function (error) {
            //TODO: This code has not been tested, simulate error and test!
            _this2.set('error', error.error);
            reject(error);
          });
        });
        this.send('pageIsDisabled', saveAndEditDraft);
      }
    }
  });
});