define("frontend/templates/components/multiple-items", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "frontend/templates/components/multiple-items.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yield", [["get", "item", ["loc", [null, [3, 4], [3, 8]]], 0, 0, 0, 0], ["subexpr", "hash", [], ["removeButton", ["subexpr", "component", ["remove-button"], ["isShowing", ["subexpr", "@mut", [["get", "hasMoreThanOneItem", ["loc", [null, [5, 56], [5, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "@mut", [["get", "deleteItemText", ["loc", [null, [5, 80], [5, 94]]], 0, 0, 0, 0]], [], [], 0, 0], "click", ["subexpr", "action", ["removeItem", ["get", "item", ["loc", [null, [5, 122], [5, 126]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 101], [5, 127]]], 0, 0]], ["loc", [null, [5, 19], [5, 128]]], 0, 0], "sortControls", ["subexpr", "component", ["sort-controls"], ["isShowing", ["subexpr", "@mut", [["get", "hasMoreThanOneItem", ["loc", [null, [6, 56], [6, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "didMoveUp", ["subexpr", "action", ["didMoveItemUp", ["get", "item", ["loc", [null, [6, 109], [6, 113]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 85], [6, 114]]], 0, 0], "didMoveDown", ["subexpr", "action", ["didMoveItemDown", ["get", "item", ["loc", [null, [7, 45], [7, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 19], [7, 50]]], 0, 0], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [7, 57], [7, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "totalNumberOfLinks", ["subexpr", "@mut", [["get", "totalNumberOfItems", ["loc", [null, [7, 82], [7, 100]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 19], [7, 101]]], 0, 0], "index", ["get", "index", ["loc", [null, [8, 12], [8, 17]]], 0, 0, 0, 0]], ["loc", [null, [4, 4], [9, 5]]], 0, 0]], [], ["loc", [null, [2, 2], [10, 4]]], 0, 0]],
        locals: ["item", "index"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "frontend/templates/components/multiple-items.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "type", "button");
        dom.setAttribute(el1, "class", "btn btn-primary");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "fa fa-plus");
        dom.setAttribute(el2, "aria-hidden", "true");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(element0, 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "each", [["get", "sortedItems", ["loc", [null, [1, 8], [1, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [11, 9]]]], ["attribute", "title", ["concat", [["get", "addItemText", ["loc", [null, [13, 76], [13, 87]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["addItem"], [], ["loc", [null, [13, 8], [13, 28]]], 0, 0], ["content", "addItemText", ["loc", [null, [13, 91], [13, 106]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});