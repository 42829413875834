define('frontend/routes/publication', ['exports', 'ember', 'frontend/config/environment'], function (exports, _ember, _frontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: function titleToken(model) {
      return model.publication.title;
    },
    model: function model(params) {
      return _ember['default'].RSVP.hash({
        publicationTypes: this.store.find('publication_type'),
        publication: this.store.find('publication', params.id)
      });
    },

    afterModel: function afterModel(models, transition) {
      // Set head tags only when publication exists
      if (models.publication.id) {
        this.setHeadTags(models);
      }
      // Dunderhack - if post id has been changed and redirected to another post update history manually
      var id = parseInt(transition.params.publication.id);
      if (models.publication.id !== id) {
        window.history.replaceState({}, 'publication', '/publication/' + models.publication.id);
      }
    },

    setupController: function setupController(controller, models) {
      controller.set("publication", models.publication);
      controller.set("publicationTypes", models.publicationTypes);

      var publicationType = models.publicationTypes.findBy('id', models.publication.publication_type_id);
      if (!models.publication.error && publicationType) {
        controller.set('selectedPublicationType', publicationType.code);
      } else {
        controller.set('publication', null);
        controller.set('selectedPublicationType', null);
      }
    },

    setHeadTags: function setHeadTags(models) {
      var headTags = [];

      headTags.push({ type: 'link', attrs: { rel: 'canonical', href: _frontendConfigEnvironment['default'].APP.publicationURL + '/' + models.publication.id } });
      if (models.publication.keywords) {
        headTags.push({ type: 'meta', attrs: { name: 'keywords', content: models.publication.keywords } });
      }
      headTags.push({ type: 'meta', attrs: { name: 'citation_title', content: models.publication.title } });
      headTags.push({ type: 'meta', attrs: { name: 'citation_publication_date', content: models.publication.pubyear } });
      if (models.publication.abstract) {
        headTags.push({ type: 'meta', attrs: { name: 'citation_abstract', content: models.publication.abstract } });
      }
      if (models.publication.publanguage) {
        headTags.push({ type: 'meta', attrs: { name: 'citation_language', content: models.publication.publanguage } });
      }
      if (models.publication.publication_identifiers) {
        models.publication.publication_identifiers.forEach(function (identifierObj) {
          if (identifierObj.identifier_code === 'doi') {
            headTags.push({ type: 'meta', attrs: { name: 'citation_doi', content: identifierObj.identifier_value } });
          }
        });
      }
      if (models.publication.authors) {
        models.publication.authors.forEach(function (authorObj) {
          var authorName = [authorObj.first_name, authorObj.last_name].join(" ").trim();
          headTags.push({ type: 'meta', attrs: { name: 'citation_author', content: authorName } });
        });
      }
      if (models.publication.files) {
        models.publication.files.forEach(function (fileObj) {
          headTags.push({ type: 'meta', attrs: { name: 'citation_pdf_url', content: _frontendConfigEnvironment['default'].APP.fileURL + '/' + fileObj.id } });
        });
      }

      this.set('headTags', headTags);
    }
  });
});