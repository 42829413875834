define('frontend/controllers/publications/dashboard/manage/show/edit', ['exports', 'ember', 'frontend/lib/validations'], function (exports, _ember, _frontendLibValidations) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    scroller: _ember['default'].inject.service(),
    publications: _ember['default'].inject.controller(),
    manageController: _ember['default'].inject.controller('publications.dashboard.manage'),
    selectedPublicationType: null,
    mayBecomeSelectedPublicationType: null,
    mayBecomeOldSelectedPublicationType: null,
    authorArr: _ember['default'].A([]),
    queryParams: ['selectPublicationVisible'],
    selectPublicationVisible: true,
    refValueBool: false,
    categoryObjectsList: _ember['default'].A([]),
    hasInvalidSelectedDepartmentItems: false,
    isShowingInvalidSelectedDepartmentsConfirmation: false,

    createNewPublicationLink: function createNewPublicationLink() {
      return _ember['default'].Object.create({
        url: ''
      });
    },

    submitCallbacks: _ember['default'].A([]), // Hack
    // Run callbacks and collect promises to resolve on submit
    submitCallbacksRun: function submitCallbacksRun() {
      return _ember['default'].RSVP.Promise.all(this.get('submitCallbacks').map(function (callback) {
        return callback();
      }));
    },

    selectedSeries: _ember['default'].computed('publication.series', {
      get: function get() {
        //TODO: ('publication.series').map()
        var pubSeries = this.get('publication.series');
        return this.get('series').filter(function (item) {
          if (!pubSeries) {
            return false;
          }
          return pubSeries.includes(parseInt(item.id));
        });
      },
      set: function set(key, value) {
        this.set('publication.series', value.map(function (item) {
          return parseInt(item.id);
        }));
        return value;
      }
    }),

    selectedProjects: _ember['default'].computed('publication.project', {
      get: function get() {
        var pubProject = this.get('publication.project');
        return this.get('projects').filter(function (item) {
          if (!pubProject) {
            return false;
          }
          return pubProject.includes(parseInt(item.id));
        });
      },
      set: function set(key, value) {
        this.set('publication.project', value.map(function (item) {
          return parseInt(item.id);
        }));
        return value;
      }
    }),

    updateCategoryObjects: _ember['default'].observer('publication.category_hsv_11.[]', function () {
      var _this = this;

      // Create list if it doesn\t exist
      if (this.get('categoryObjectsList') === undefined) {
        this.set('categoryObjectsList', _ember['default'].A([]));
      }

      // Fetch objects if they aren't loaded
      if (this.get('publication.category_hsv_11')) {
        this.get('publication.category_hsv_11').forEach(function (item) {
          var categoryObject = _this.get('categoryObjectsList').findBy('id', item);
          if (_ember['default'].isEmpty(categoryObject)) {
            _this.store.find('category', item).then(function (response) {
              _this.categoryObjectsList.pushObject(response);
            }, function () {
              //TODO: handle? propagate?
            });
          }
        });
      }
      // Remove objects which are no longer part of category list
      this.get('categoryObjectsList').forEach(function (item) {
        if (_this.get('publication.category_hsv_11').indexOf(item.id) === -1) {
          _this.get('categoryObjectsList').removeObject(item);
        }
      });
    }),

    publicationYearDepartments: _ember['default'].computed('publication.pubyear', 'institutions', function () {
      this.get("institutions").forEach(function (item) {
        item.children = null;
      });

      var publicationYear = parseInt(this.get('publication.pubyear'));
      // If no valid year, return all departments
      // (this may not be a good idea)
      if (!(0, _frontendLibValidations.validYear)(publicationYear.toString())) {
        return this.get('institutions');
      }
      return this.get('institutions').filter(function (department) {
        // TODO: Warning if wrong type?
        // TODO: This is perhaps not the place to check types,
        // assume correct types?
        return (_ember['default'].isBlank(department.start_year) || _ember['default'].typeOf(department.start_year) !== 'number' || department.start_year <= publicationYear) && (_ember['default'].isBlank(department.end_year) || _ember['default'].typeOf(department.end_year) !== 'number' || department.end_year >= publicationYear);
      });
    }),
    getPublicationTypeObject: _ember['default'].computed('selectedPublicationType', 'publicationTypes', function () {
      var fullObjectPubtype = this.get('publicationTypes').findBy('code', this.get('selectedPublicationType'));
      return fullObjectPubtype;
    }),

    scrollTop: (function () {
      window.scrollTo(0, 0);
    }).observes('errors', 'selectPublicationVisible'),

    updateModelWithCorrectPublicationType: (function () {
      this.set('publication.publication_type_id', this.get('getPublicationTypeObject.id'));
    }).observes('selectedPublicationType'),

    /* author-block */
    formatAuthorsForServer: function formatAuthorsForServer() {
      var authors = this.get('authorArr').map(function (author) {
        if (!_ember['default'].isEmpty(author.selectedAuthor)) {
          var departments = [];
          departments = author.get('selectedInstitution').map(function (department) {
            return { id: department.id, name: department.name };
          });
          if (_ember['default'].isEmpty(departments)) {
            departments.addObject({ id: '666', name: 'Extern institution' });
          }
          return { id: author.selectedAuthor.id, departments: departments };
        }
        return null;
      }).compact();
      this.set('publication.authors', authors);
    },

    authorComponentDisabled: (function () {
      return !this.get('showRegisterNewAuthor');
    }).property('showRegisterNewAuthor'),

    authorComponentIsVisible: (function () {
      return this.get('isSelectedPublicationValid');
    }).property('selectedPublicationType'),

    /* end author-block */

    isSelectedPublicationValid: (function () {
      return !(this.get('selectedPublicationType') === '- Välj -' || _ember['default'].isEmpty(this.get('selectedPublicationType')));
    }).property('selectedPublicationType'),

    actionButtonsAreVisible: (function () {
      return this.get('isSelectedPublicationValid');
    }).property('selectedPublicationType'),

    selectPublicationTypeIsVisible: (function () {
      if (!this.get('isSelectedPublicationValid')) {
        this.set('selectPublicationVisible', true);
        return true;
      } else {
        this.set('selectPublicationVisible', false);
        return false;
      }
    }).observes('selectedPublicationType'),

    refValueSelectionVisible: _ember['default'].computed.equal('publicationTypeObject.ref_options', 'BOTH'),

    refInfoTextVisible: _ember['default'].computed('selectedPublicationType', function () {
      return this.get('selectedPublicationType') === 'artistic-work_original-creative-work';
    }),

    getAuthorsHelptext: _ember['default'].computed('selectedPublicationType', function () {
      if (this.get('selectedPublicationType') === 'artistic-work_original-creative-work') {
        return "publications.publicationtypes.form.help.authors.helptext.artistic";
      } else {
        return "publications.publicationtypes.form.help.authors.helptext.general";
      }
    }),

    changeRefValue: _ember['default'].observer('refValueBool', function () {
      if (this.get('refValueBool')) {
        this.set('publication.ref_value', 'ISREF');
      } else {
        this.set('publication.ref_value', 'NOTREF');
      }
    }),

    refValueChanged: _ember['default'].observer('publication.ref_value', function () {
      this.set('refValueBool', this.get('publication.ref_value') === 'ISREF');
    }),

    publicationTypeObject: _ember['default'].computed('selectedPublicationType', function () {
      return this.get('publicationTypes').findBy('code', this.get('selectedPublicationType'));
    }),

    descriptionOfMayBecomeSelectedPublicationType: (function () {
      var fullObj = this.get('publicationTypes').findBy('code', this.get('mayBecomeSelectedPublicationType'));
      if (fullObj) {
        return fullObj.description;
      } else {
        return null;
      }
    }).property('mayBecomeSelectedPublicationType'),

    publicationTypeFilter: 'all',

    showPublicationTypeGroupBooks: _ember['default'].computed('publicationTypeFilter', function () {
      return this.get('publicationTypeFilter') === 'books' || this.get('publicationTypeFilter') === 'all';
    }),
    showPublicationTypeGroupArticles: _ember['default'].computed('publicationTypeFilter', function () {
      return this.get('publicationTypeFilter') === 'articles' || this.get('publicationTypeFilter') === 'all';
    }),
    showPublicationTypeGroupConference: _ember['default'].computed('publicationTypeFilter', function () {
      return this.get('publicationTypeFilter') === 'conference' || this.get('publicationTypeFilter') === 'all';
    }),
    showPublicationTypeGroupArtworks: _ember['default'].computed('publicationTypeFilter', function () {
      return this.get('publicationTypeFilter') === 'artworks' || this.get('publicationTypeFilter') === 'all';
    }),
    showPublicationTypeGroupOther: _ember['default'].computed('publicationTypeFilter', function () {
      return this.get('publicationTypeFilter') === 'other' || this.get('publicationTypeFilter') === 'all';
    }),
    isSelectedAll: _ember['default'].computed.equal('publicationTypeFilter', 'all'),
    isSelectedBooks: _ember['default'].computed.equal('publicationTypeFilter', 'books'),
    isSelectedArticles: _ember['default'].computed.equal('publicationTypeFilter', 'articles'),
    isSelectedConference: _ember['default'].computed.equal('publicationTypeFilter', 'conference'),
    isSelectedArtworks: _ember['default'].computed.equal('publicationTypeFilter', 'artworks'),
    isSelectedOther: _ember['default'].computed.equal('publicationTypeFilter', 'other'),

    actions: {
      sanitizePublicationLink: function sanitizePublicationLink(link) {
        link.set('url', link.get('url').trim());
        if (_ember['default'].isPresent(link.get('url')) && !/^\w+:\/\//.test(link.get('url'))) {
          link.set('url', 'http://' + link.get('url'));
        }
      },
      setPublicationTypeFilter: function setPublicationTypeFilter(filter) {
        this.set('publicationTypeFilter', filter);
      },
      setAsSelectedPublicationType: function setAsSelectedPublicationType() {
        if (this.get('mayBecomeSelectedPublicationType')) {
          this.set('selectedPublicationType', this.get('mayBecomeSelectedPublicationType'));
        }
      },
      setPublicationType: function setPublicationType(publicationType) {
        this.set('selectedPublicationType', publicationType);
        var ref_options = this.get('publicationTypeObject.ref_options');
        if (ref_options !== 'BOTH') {
          this.set('publication.ref_value', ref_options);
        } else {
          this.set('publication.ref_value', 'NOTREF');
        }
      },
      resetSelectedPublicationType: function resetSelectedPublicationType() {
        this.set('mayBecomeOldSelectedPublicationType', this.get('selectedPublicationType'));
        this.set('mayBecomeSelectedPublicationType', this.get('selectedPublicationType'));
        this.set('selectedPublicationType', null);
      },

      /* author-block */
      toggleAddNewAuthor: function toggleAddNewAuthor(id) {
        var obj = this.get('authorArr').findBy('id', id);
        if (obj.get('transformedToNewAuthor') === true) {
          obj.set('transformedToNewAuthor', false);
        } else {
          obj.set('transformedToNewAuthor', true);
        }
      },
      /* end author-block */

      cancelChangePublicationType: function cancelChangePublicationType() {
        this.set('selectedPublicationType', this.get('mayBecomeOldSelectedPublicationType'));
      },

      // Dummy catcher for field-component without a surrounding field-group
      countContent: function countContent() {
        return false;
      },

      invalidSelectedDepartmentItemsChanged: function invalidSelectedDepartmentItemsChanged(items) {
        this.set('hasInvalidSelectedDepartmentItems', _ember['default'].isPresent(items));
      },

      saveDraft: function saveDraft() {
        this.send('savePublication', true);
      },
      savePublish: function savePublish() {
        this.send('savePublication', false);
      },

      maybeSave: function maybeSave(saveAction) {
        if (this.get('hasInvalidSelectedDepartmentItems')) {
          this.set('saveAction', saveAction);
          this.set('isShowingInvalidSelectedDepartmentsConfirmation', true);
        } else {
          this.set('isShowingInvalidSelectedDepartmentsConfirmation', false);
          saveAction();
        }
      },
      maybeSaveAction: function maybeSaveAction() {
        this.get('saveAction')();
        // TODO: Not needed right now, refactor confirmation-modal not to set this internally since bad ember practice
        // and uncomment this line?
        //this.set('isShowingInvalidSelectedDepartmentsConfirmation', false);
      }
    }
  });
});