define('frontend/routes/publications/dashboard/manage/show', ['exports', 'ember', 'frontend/mixins/resetscroll', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _frontendMixinsResetscroll, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _frontendMixinsResetscroll['default'], {
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      // return this.get("i18n").t('publications.dashboard.manage.show.title');
    },

    beforeModel: function beforeModel() {
      // TODO: loading substate instead (below should not work?)
    },

    model: function model(params) {
      return this.store.find('publication', params.id);
    },

    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.set("manageController.isNavVisible", false);
    },

    afterModel: function afterModel() /*model, transition */{},

    actions: {
      refreshModel: function refreshModel(modelId) {
        this.refresh(modelId);
      },
      error: function error() {
        this.send('setMsgHeader', 'error', this.get('i18n').t('publications.dashboard.manage.show.publicationNotFound'));
        // this.transitionTo('publications.dashboard.manage.start');
      }
    }
  });
});