define("frontend/templates/components/gup-file-upload-widget", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 5,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            dom.setAttribute(el1, "class", "modal-title");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "modalTitle", ["loc", [null, [4, 28], [4, 42]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 4
                },
                "end": {
                  "line": 11,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "alert alert-danger");
              dom.setAttribute(el1, "role", "alert");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["content", "error", ["loc", [null, [9, 8], [9, 17]]], 0, 0, 0, 0]],
            locals: ["error"],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 23,
                      "column": 8
                    },
                    "end": {
                      "line": 25,
                      "column": 8
                    }
                  },
                  "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "bs-progress-bar", [], ["value", ["subexpr", "@mut", [["get", "fileUploadProgress", ["loc", [null, [24, 34], [24, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "minValue", 0, "maxValue", 100, "showLabel", false, "id", ["subexpr", "concat", [["get", "id", ["loc", [null, [24, 104], [24, 106]]], 0, 0, 0, 0], "-progress"], [], ["loc", [null, [24, 96], [24, 119]]], 0, 0]], ["loc", [null, [24, 10], [24, 121]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 6
                  },
                  "end": {
                    "line": 26,
                    "column": 6
                  }
                },
                "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "bs-progress", [], [], 0, null, ["loc", [null, [23, 8], [25, 24]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 4
                },
                "end": {
                  "line": 27,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "fa fa-paperclip");
              dom.setAttribute(el2, "aria-hidden", "true");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createAttrMorph(element1, 'for');
              morphs[2] = dom.createMorphAt(element1, 2, 2);
              morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "file-upload", [], ["id", ["subexpr", "concat", [["get", "id", ["loc", [null, [14, 17], [14, 19]]], 0, 0, 0, 0], "-file-upload"], [], ["loc", [null, [14, 9], [14, 35]]], 0, 0], "class", "gup-input-upload", "fileUploadProgress", ["subexpr", "@mut", [["get", "fileUploadProgress", ["loc", [null, [16, 25], [16, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "fileDidChange", ["subexpr", "action", ["fileDidChange"], [], ["loc", [null, [17, 20], [17, 44]]], 0, 0], "uploadUrl", ["subexpr", "@mut", [["get", "uploadUrl", ["loc", [null, [18, 16], [18, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "parentUploadFile", ["subexpr", "mut", [["get", "parentUploadFile", ["loc", [null, [19, 28], [19, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 23], [19, 45]]], 0, 0], "uploadExtraData", ["subexpr", "@mut", [["get", "uploadExtraData", ["loc", [null, [20, 22], [20, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 6], [20, 39]]], 0, 0], ["attribute", "for", ["concat", [["get", "id", ["loc", [null, [21, 20], [21, 22]]], 0, 0, 0, 0], "-file-upload"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "chooseFileLabelOrFileLabel", ["loc", [null, [21, 89], [21, 119]]], 0, 0, 0, 0], ["block", "if", [["get", "fileUploadProgress", ["loc", [null, [22, 12], [22, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 6], [26, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 2
              },
              "end": {
                "line": 29,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "each", [["get", "errors", ["loc", [null, [7, 12], [7, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 4], [11, 13]]]], ["block", "if", [["get", "fileUploadIsVisible", ["loc", [null, [12, 10], [12, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [12, 4], [27, 11]]]], ["content", "yield", ["loc", [null, [28, 4], [28, 13]]], 0, 0, 0, 0]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 4
                  },
                  "end": {
                    "line": 32,
                    "column": 94
                  }
                },
                "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "cancelLabel", ["loc", [null, [32, 79], [32, 94]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 4
                },
                "end": {
                  "line": 33,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "bs-button", [], ["action", ["subexpr", "action", ["close"], ["target", ["get", "footer", ["loc", [null, [32, 47], [32, 53]]], 0, 0, 0, 0]], ["loc", [null, [32, 24], [32, 54]]], 0, 0], "type", ["subexpr", "@mut", [["get", "cancelButtonStyle", ["loc", [null, [32, 60], [32, 77]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [32, 4], [32, 108]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 4
                },
                "end": {
                  "line": 36,
                  "column": 4
                }
              },
              "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "type", "button");
              dom.setAttribute(el1, "class", "btn btn-primary");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'disabled');
              morphs[1] = dom.createElementMorph(element0);
              morphs[2] = dom.createMorphAt(element0, 0, 0);
              return morphs;
            },
            statements: [["attribute", "disabled", ["get", "submitButtonIsDisabled", ["loc", [null, [35, 84], [35, 106]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["didSave"], [], ["loc", [null, [35, 52], [35, 72]]], 0, 0], ["content", "submitLabel", ["loc", [null, [35, 109], [35, 124]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 2
              },
              "end": {
                "line": 37,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "cancelButtonIsVisible", ["loc", [null, [31, 10], [31, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [31, 4], [33, 11]]]], ["block", "if", [["get", "submitButtonIsVisible", ["loc", [null, [34, 10], [34, 31]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [34, 4], [36, 11]]]]],
          locals: ["footer"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 38,
              "column": 0
            }
          },
          "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "bs-modal-header", [], [], 0, null, ["loc", [null, [3, 2], [5, 22]]]], ["block", "bs-modal-body", [], [], 1, null, ["loc", [null, [6, 2], [29, 20]]]], ["block", "bs-modal-footer", [], [], 2, null, ["loc", [null, [30, 2], [37, 22]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 0
          }
        },
        "moduleName": "frontend/templates/components/gup-file-upload-widget.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "type", "button");
        dom.setAttribute(el1, "class", "btn btn-link");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "aria-hidden", "true");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createElementMorph(element2);
        morphs[1] = dom.createAttrMorph(element3, 'class');
        morphs[2] = dom.createMorphAt(element2, 2, 2);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["element", "action", ["showUploadModal"], [], ["loc", [null, [1, 8], [1, 36]]], 0, 0], ["attribute", "class", ["concat", ["fa ", ["get", "faIconClass", ["loc", [null, [1, 87], [1, 98]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "openModalLabel", ["loc", [null, [1, 126], [1, 144]]], 0, 0, 0, 0], ["block", "bs-modal", [], ["open", ["subexpr", "@mut", [["get", "isShowingUploadModal", ["loc", [null, [2, 17], [2, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "header", false, "footer", false, "body", false, "closeAction", ["subexpr", "action", ["didCancel"], [], ["loc", [null, [2, 87], [2, 107]]], 0, 0]], 0, null, ["loc", [null, [2, 0], [38, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});