define('frontend/helpers/error-feedback', ['exports', 'ember'], function (exports, _ember) {
	exports.errorFeedback = errorFeedback;

	function errorFeedback(arr) {
		var str = '<ul>';
		arr.forEach(function (item) {
			str = str + '<li>' + item + '</li>';
		});
		str = str + "</ul>";
		return new _ember['default'].Handlebars.htmlSafe('<i class="popovers fa fa-info-circle hand" data-toggle="popover" title="Felinformation" data-content="<b>' + str + '</b>"></i>');
	}

	exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(errorFeedback);
});