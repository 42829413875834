define('frontend/routes/admin/people/person/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('person', params.id);
    },
    setupController: function setupController(controller, model) {
      // Extract xaccount and orcid from array of identifiers into a
      // specific xaccount/orcid property to simplify output in result lists
      model.identifiers.forEach(function (identifier) {
        if (identifier.source_name === 'xkonto') {
          _ember['default'].set(model, 'xaccount', identifier.value);
        }
        if (identifier.source_name === 'orcid') {
          _ember['default'].set(model, 'orcid', identifier.value);
        }
      });
      controller.set('errors', {});
      controller.set('lastQuery', sessionStorage.getItem('admin.people.lastQuery'));
      controller.set('model', model);
    },
    actions: {
      savePerson: function savePerson(model) {
        var _this = this;

        var successHandler = function successHandler() {
          sessionStorage.setItem('admin.people.changeWarning', true);
          _this.transitionTo('admin.people', { queryParams: { qp: sessionStorage.getItem('admin.people.lastQuery') } });
        };
        var errorHandler = function errorHandler(reason) {
          _this.send('setMsgHeader', 'error', reason.error.msg);
          _this.controller.set('errors', reason.error.errors);
          _ember['default'].run.later(function () {
            _ember['default'].$('[data-toggle="popover"]').popover({
              placement: 'top',
              html: true
            });
          });
        };
        var generalHandler = function generalHandler(model) {
          if (model.error) {
            errorHandler(model);
          } else {
            successHandler(model);
          }
        };
        var person_data = {
          id: model.id,
          first_name: model.first_name,
          last_name: model.last_name,
          year_of_birth: model.year_of_birth,
          xaccount: model.xaccount,
          orcid: model.orcid
        };
        this.store.save('person', person_data).then(generalHandler);
      }
    }
  });
});