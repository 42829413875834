define('frontend/initializers/inject-store', ['exports', 'frontend/models/store'], function (exports, _frontendModelsStore) {
  exports['default'] = {
    name: 'inject-store',
    initialize: function initialize(app) {
      app.register('store:main', _frontendModelsStore['default']);
      app.inject('route', 'store', 'store:main');
      app.inject('controller', 'store', 'store:main');
      app.inject('component', 'store', 'store:main');
    }
  };
});