define('frontend/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  //import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    i18n: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    title: function title(tokens) {
      return tokens.join(' - ') + ' - ' + this.get('i18n').t('application.title');
    },
    beforeModel: function beforeModel(params) {
      var lang = 'sv'; /// change to default

      if (params.queryParams.lang) {
        lang = params.queryParams.lang;
      } else if (sessionStorage.getItem('lang')) {
        lang = sessionStorage.getItem('lang');
      }

      this.set('i18n.locale', lang);
      sessionStorage.setItem('lang', lang);

      this._super();
    },

    sessionAuthenticated: function sessionAuthenticated() {
      // https://github.com/simplabs/ember-simple-auth/issues/1618
      if (this.get('session.previousRouteName')) {
        this.transitionTo(this.get('session.previousRouteName'));
      } else {
        this._super.apply(this, arguments);
      }
    },
    actions: {

      willTransition: function willTransition(transition) {
        var _this = this;

        if (window.location.pathname !== "/login") {
          this.set('session.previousRouteName', window.location.pathname + "/" + window.location.search);
        }
        transition.then(function () {
          // https://github.com/simplabs/ember-simple-auth/issues/1618 (modified above to add queryparams)
          if (window.location.pathname !== '/login') {
            _this.set('session.previousRouteName', window.location.pathname);
          }
        });
      },
      toggleLang: function toggleLang() {
        if (this.get('i18n.locale') === 'sv') {
          this.set('i18n.locale', 'en');
          this.set("lang", 'en');
          sessionStorage.setItem('lang', 'en');
        } else {
          this.set('i18n.locale', 'sv');
          sessionStorage.setItem('lang', 'sv');
          this.set("lang", 'sv');
        }
        _ember['default'].run.later(function () {
          //location.reload(true);
        });
      },

      loading: function loading(transition) {
        var controller = this.controllerFor('application');
        controller.set('currentlyLoading', true);
        transition.promise['finally'](function () {
          controller.set('currentlyLoading', false);
        });
        return true;
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      refreshUserdata: function refreshUserdata() {
        var _this2 = this;

        this.store.find('userdata', this.get('session.data.authenticated.username')).then(function (data) {
          _this2.controllerFor("application").set("userdata", data);
        });
      },
      sessionAuthenticationFailed: function sessionAuthenticationFailed(error) {
        this.controllerFor('login').set('error', error.msg);
      },
      hideMsgHeader: function hideMsgHeader() {
        this.controller.set('showMsgHeader', false);
        this.controller.set('hasErrors', false);
        this.controller.set('errors', '');
        this.controller.set('mesg', '');
      },
      setMsgHeader: function setMsgHeader(type, msg) {
        var _this3 = this;

        this.controller.set('showMsgHeader', true);
        this.controller.set('msgType', type);
        this.controller.set('msg', msg);
        _ember['default'].run.later(function () {
          _this3.send('hideMsgHeader');
        }, 2500);
      },
      showPublication: function showPublication(publication_id) {
        var _this4 = this;

        this.controller.set('publication_id', null);
        this.controller.set('publication_id_error', null);

        var successHandler = function successHandler() {
          _this4.transitionTo('publications.dashboard.manage.show', publication_id);
        };
        var errorHandler = function errorHandler() {
          _this4.controller.set('publication_id_error', _this4.get('i18n').t('mainMenu.idMissing') + ': ' + publication_id);
        };
        var generalHandler = function generalHandler(model) {
          if (model.error) {
            errorHandler();
          } else {
            successHandler();
          }
        };
        if (publication_id) {
          this.store.find('publication', publication_id).then(generalHandler);
        }
      },
      pageIsDisabled: function pageIsDisabled(promise) {
        var controller = this.controllerFor('application');
        controller.set('pageIsDisabled', true);

        _ember['default'].run.schedule('afterRender', this, function () {
          var promisePending = true;
          _ember['default'].$('#page-disabled-overlay').fadeTo(400, 0.25, function () {
            if (promisePending) {
              controller.set('currentlyLoading', true);
            }
          });
          var enablePage = function enablePage() {
            promisePending = false;
            controller.set('currentlyLoading', false);
            //afterRender once again to be safe/correct?
            _ember['default'].$('#page-disabled-overlay').stop(true, false).fadeTo(200, 1, function () {
              _ember['default'].run(function () {
                controller.set('pageIsDisabled', false);
              });
            });
          };
          // Restore page both regardless of resolved or rejected
          promise.then(enablePage, enablePage);
        });
      }
    }
  });
});