define('frontend/components/file-upload', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  exports['default'] = _emberUploader['default'].FileField.extend({
    session: _ember['default'].inject.service('session'),
    multiple: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('resetState', function () {
        _this.set('value', '');
        // Only triggers filesDidChange if not empty:
        //https://github.com/benefitcloud/ember-uploader/blob/master/addon/components/file-field.js
        //TODO: this is weird
        _this.filesDidChange(null);
        _this.set('fileUploadProgress', null);
      });
      //this.attrs.parentResetState.update(this.get('resetState'));

      this.set('uploadFile', function (file) {
        var token = _this.get('session.data.authenticated.token');
        if (!token) {
          _ember['default'].RSVP.Promise.reject('Invalid token');
        }

        var preFilters = _ember['default'].$.Callbacks();

        var authPrefilter = function authPrefilter(options) {
          if (!options.headers) {
            options.headers = {};
          }
          options.headers['Authorization'] = "Token " + token;
          return options;
        };

        var uploader = _emberUploader['default'].Uploader.create({
          url: _this.get('uploadUrl')
        });

        uploader.on('progress', function (e) {
          // Handle progress changes
          // Use `e.percent` to get percentage
          _this.set('fileUploadProgress', e.percent);
        });

        _ember['default'].$.ajaxPrefilter(preFilters.fire);
        preFilters.add(authPrefilter);

        var data = _ember['default'].isPresent(_this.get('uploadExtraData')) ? _this.get('uploadExtraData') : {}; //Hmm??
        return uploader.upload(file, data)['catch'](function (errorResponse) {
          var errorMsg = 'responseJSON' in errorResponse ? errorResponse.responseJSON.error.msg : errorResponse.statusText;
          _this.set('fileUploadProgress', null);
          throw errorMsg;
        })['finally'](function () {
          preFilters.remove(authPrefilter);
        });
      });
      // Force two way binding
      if (this.attrs.parentUploadFile !== undefined) {
        this.attrs.parentUploadFile.update(this.get('uploadFile'));
      }
    },
    filesDidChange: function filesDidChange(files) {
      var file = _ember['default'].isEmpty(files) ? files : files[0];
      this.sendAction('fileDidChange', file);
    }
  });
});