define('frontend/components/debounced-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({
    debounceWait: 500,
    fireAtStart: true,

    _elementValueDidChange: function _elementValueDidChange() {
      _ember['default'].run.debounce(this, this._setValue, this.debounceWait, this.fireAtStart);
    },
    _setValue: function _setValue() {
      this.set('value', this.$().val());
    }
  });
});
//
// To be used like this:
//
//
// {{debounced-input
// placeholder="1000000"
// value=propertyName
// debounceWait=300 <-- debounce wait value
// fireAtStart=false <-- corresponds to Ember.run.debounce’s 4th param, if false, will run at the end of wait period
// class="form-control" <-- all regular text input attributes work
// name="price"
// }}