define('frontend/components/sort-controls', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isShowing: true,

    isFirst: _ember['default'].computed('index', function () {
      if (this.get("index") === 0) {
        return "is-first";
      }
      return;
    }),
    isLast: _ember['default'].computed('index', 'totalNumberOfLinks', function () {
      if (this.get("index") + 1 === this.get('totalNumberOfLinks')) {
        return 'is-last';
      }
      return;
    })
  });
});