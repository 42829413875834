define('frontend/helpers/format-date', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {

  function formatDate(params) {
    var date = params[0];
    var format = params[1] || "YYYY-MM-DD HH:mm";
    var tempDateObj = (0, _moment['default'])(date);
    return new _ember['default'].Handlebars.SafeString(tempDateObj.format(format));
  }

  exports.formatDate = formatDate;
  exports['default'] = _ember['default'].Helper.helper(formatDate);
});