define('frontend/components/delay-review-modal', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    classNames: ['delay-review-modal'],
    setMsgHeaderAction: 'setMsgHeader',
    optDate: 'calendar',
    optText: 'freetext',

    startDate: _ember['default'].computed('publication.biblreview_postponed_until', function () {
      return (0, _moment['default'])(this.get('publication.biblreview_postponed_until')).format('YYYY-MM-DD');
    }),
    isDelayed: _ember['default'].computed('publication.biblreview_postponed_until', function () {
      if (!this.get('publication.biblreview_postponed_until')) {
        return false;
      }
      if ((0, _moment['default'])(this.get('publication.biblreview_postponed_until')) > (0, _moment['default'])()) {
        return true;
      }
      return false;
    }),

    datePickerDisabled: _ember['default'].computed('optDate', function () {
      return this.get('optDate') !== 'calendar';
    }),

    commentFieldDisabled: _ember['default'].computed('optText', function () {
      return this.get('optText') !== 'freetext';
    }),
    actions: {
      dateOptionChanged: function dateOptionChanged() {
        switch (this.get('optDate')) {
          case 'onemonth':
            this.set('date', (0, _moment['default'])().add(1, 'months'));
            break;
          case 'threemonths':
            this.set('date', (0, _moment['default'])().add(3, 'months'));
            break;
          case 'sixmonths':
            this.set('date', (0, _moment['default'])().add(6, 'months'));
            break;
        }
      },

      textOptionChanged: function textOptionChanged() {
        switch (this.get('optText')) {
          case 'freetext':
            break;
          case 'e-pub-ahead':
            this.set('comment', 'E-pub ahead of print');
            break;
        }
      },

      sendDelay: function sendDelay() {
        var _this = this;

        var publication = this.get('publication');
        var date = (0, _moment['default'])(this.get('date')).format('YYYY-MM-DD');
        var comment = this.get('comment');
        this.store.save('postpone_date', { publication_id: publication.id, postponed_until: date, comment: comment }).then(function () {
          _this.sendAction('setMsgHeaderAction', 'success', _this.get('i18n').t('components.delayReviewModal.delaySuccess'));
          _this.get('targetObject').transitionToRoute('publications.dashboard.biblreview');
        }, function () {
          _this.sendAction('setMsgHeaderAction', 'error', _this.get('i18n').t('components.delayReviewModal.delayError'));
        });
      }
    }
  });
});