define('frontend/components/field-group', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['field-group'],
    hasContent: false,
    contentFields: [],
    init: function init() {
      this.set('contentFields', []);
      this._super(arguments);
    },
    actions: {
      countContent: function countContent(field_name) {
        this.set('hasContent', true);
        this.get('contentFields').push(field_name);
      }
    }
  });
});