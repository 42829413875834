define('frontend/controllers/admin/people/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['qp'],
    qp: '',
    query: '',

    actions: {
      sendQuery: function sendQuery() {
        this.set('qp', this.get('query'));
      }
    }
  });
});