define('frontend/routes/publications/dashboard/manage/published', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('publications.dashboard.manage.published.title');
    },
    queryParams: {
      page: { refreshModel: true },
      sort_by: { refreshModel: true }
    },
    afterModel: function afterModel(model, transition) {
      this.controllerFor('application').set('currentList', transition.targetName);
    },
    model: function model(params) {
      if (!params.page) {
        params.page = 1;
      }
      params.actor = 'logged_in_user';
      return this.store.find('published_publication', params);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('manageController.isNavVisible', true);
      if (controller.get('sortSelectValues').length === 0) {
        controller.get('sortSelectValues').pushObjects([{ value: 'pubyear', label: this.get("i18n").t('publications.dashboard.manage.published.sortByYearLabel') }, { value: 'title', label: this.get("i18n").t('publications.dashboard.manage.published.sortByTitleLabel') }]);
      }
    }
  });
});