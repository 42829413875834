define("frontend/templates/components/collapsible-items", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "frontend/templates/components/collapsible-items.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yield", [["get", "itemState.item", ["loc", [null, [4, 4], [4, 18]]], 0, 0, 0, 0], ["subexpr", "hash", [], ["toggleIsActive", ["subexpr", "action", ["toggleActiveItem", ["get", "itemState.item", ["loc", [null, [6, 48], [6, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 21], [6, 63]]], 0, 0], "isActive", ["get", "itemState.isActive", ["loc", [null, [7, 15], [7, 33]]], 0, 0, 0, 0], "index", ["get", "index", ["loc", [null, [8, 12], [8, 17]]], 0, 0, 0, 0]], ["loc", [null, [5, 4], [9, 5]]], 0, 0]], [], ["loc", [null, [3, 2], [10, 4]]], 0, 0]],
        locals: ["itemState", "index"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "frontend/templates/components/collapsible-items.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "itemStates", ["loc", [null, [1, 8], [1, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [12, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});