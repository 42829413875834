define('frontend/routes/publications/dashboard/manage/drafts', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		i18n: _ember['default'].inject.service(),
		titleToken: function titleToken() {
			return this.get("i18n").t('publications.dashboard.manage.drafts.title');
		},

		queryParams: {
			page: { refreshModel: true }
		},

		beforeModel: function beforeModel() {},
		model: function model(params) {
			if (!params.page) {
				params.page = 1;
			}
			return this.store.find("draft", params);
		},
		afterModel: function afterModel(model, transition) {
			this.controllerFor('application').set('currentList', transition.targetName);
		},

		setupController: function setupController(controller, model) {
			controller.set("model", model);
			controller.set("manageController.isNavVisible", true);
		},

		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			}

		}
	});
});