define('frontend/components/publication-field-bibl-review', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'tr',

    isCollapsed: true,

    isTypeLinks: _ember['default'].computed.equal('type', 'links'),

    isTypeList: _ember['default'].computed.equal('type', 'list'),

    isTypeAuthors: _ember['default'].computed.equal('type', 'authors'),

    isTypeCollapsible: _ember['default'].computed.equal('type', 'collapsed'),

    listValueArray: _ember['default'].computed('fieldValue', 'listLabel', 'listValue', function () {

      var listValue = this.get('listValue');
      var listLabel = this.get('listLabel');

      return this.get('fieldValue').map(function (listItem) {
        if (listLabel) {
          return listItem[listLabel] + ': ' + listItem[listValue];
        } else {
          return listItem[listValue];
        }
      });
    }),

    collapsedValue: _ember['default'].computed('fieldValue', function () {
      //var collapsedLength = 150;
      var thresholdLength = 200;
      var fieldValue = this.get('fieldValue');
      if (fieldValue && fieldValue.length > thresholdLength) {
        return fieldValue.substring(0, thresholdLength) + '...';
      } else {
        return null;
      }
    }),

    actions: {
      toggleCollapsed: function toggleCollapsed() {
        this.toggleProperty('isCollapsed');
      }
    }

  });
});