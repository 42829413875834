define('frontend/routes/publications/dashboard/reports', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('publications.dashboard.reports.title');
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        departments: this.store.find('department')
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      controller.set('departments', models.departments);
      controller.set('filter', {});
      controller.set('columns', {});
      controller.set('model', null);
      var pubTypes = controller.get('publicationsController.publicationTypes').map(function (item) {
        return {
          name: item.name,
          code: item.code,
          checked: false
        };
      });
      controller.set('publicationTypes', pubTypes);
    },
    actions: {
      createReport: function createReport(filter, columns) {
        var _this = this;

        this.store.save('report', { filter: filter, columns: columns }).then(function (model) {
          _this.controller.set('reportRowsColumns', columns);
          _this.controller.set('model', model);
          window.scrollTo(0, 0);
        });
      },
      resetFilter: function resetFilter() {
        this.controller.set('filter', {});
        this.controller.get('publicationTypes').setEach('checked', false);
        this.controller.set('person', null);
        window.scrollTo(0, 0);
      },
      didTransition: function didTransition() {
        this.controller.set('filter', {});
        this.controller.get('publicationTypes').setEach('checked', false);
        this.controller.set('person', null);
      }
    }
  });
});