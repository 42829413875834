define('frontend/components/publicationtype-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    publicationType: _ember['default'].computed('code', 'publicationTypes', function () {
      return this.get('publicationTypes').findBy('code', this.get('code'));
    }),

    setPublicationTypeAction: 'setPublicationType',

    actions: {
      setPublicationType: function setPublicationType() {
        this.sendAction('setPublicationTypeAction', this.get('code'));
      }
    }
  });
});