define('frontend/controllers/publications/dashboard/review', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      showMessage: function showMessage(type, msg) {
        this.send('setMsgHeader', type, msg);
      },
      refreshReviewCount: function refreshReviewCount() {
        this.send('refreshUserdata');
      }
    }
  });
});