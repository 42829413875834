define('frontend/helpers/format-authors', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.formatAuthors = formatAuthors;

  function formatAuthors(_ref) {
    var _ref2 = _slicedToArray(_ref, 3);

    var authors = _ref2[0];
    var from = _ref2[1];
    var to = _ref2[2];

    var arr = authors.slice(from, to);
    return arr.map(function (author) {
      return [author.first_name, author.last_name].map(function (name) {
        return _ember['default'].isPresent(name) ? name : null;
      }).compact().join(' ');
    }).join(', ');
  }

  exports['default'] = _ember['default'].Helper.helper(formatAuthors);
});