define('frontend/routes/publications/dashboard/manage/show/edit', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/resetscroll'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _frontendMixinsResetscroll) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _frontendMixinsResetscroll['default'], {
    i18n: _ember['default'].inject.service(),

    activate: function activate() {
      this._super.apply(this, arguments); // Call super at the beginning
      // Your stuff
    },
    titleToken: function titleToken() {
      return this.get('i18n').t('publications.dashboard.manage.show.edit.title');
    },
    returnTo: null,
    beforeModel: function beforeModel(transition) {
      this.set('returnTo', transition.queryParams.returnTo);
      this.set('returnToModels', transition.queryParams.returnToModels);
      this.set('returnToQueryParams', transition.queryParams.returnToQueryParams);
    },
    model: function model() {
      var model = this.modelFor('publications.dashboard.manage.show');
      return _ember['default'].RSVP.hash({
        publication: this.store.find('publication', model.id),
        publicationTypes: this.store.find('publication_type'),
        departments: this.store.find('department'),
        languages: this.store.find('language'),
        series: this.store.find('serie'),
        projects: this.store.find('project'),
        publicationIdentifierCodes: this.store.find('publication_identifier_code'),
        artworkTypes: this.store.find('artwork_type')
      });
    },
    afterModel: function afterModel() /*model, transition*/{},

    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      //TODO: Remove this when binding issue fixed
      if (_ember['default'].isBlank(models.publication.publication_links)) {
        models.publication.publication_links = [{ url: '', position: 0 }];
        //models.publication.publication_links.pushObject({url: '', position: 0});
      }

      // GUP adapter does not return ember objects, which are needed for multiple-items component to work(?)
      models.publication.publication_links = models.publication.publication_links.map(function (item) {
        return _ember['default'].Object.create(item);
      });

      controller.set('publicationTypes', models.publicationTypes);
      controller.set('publication', models.publication);
      controller.set('categories', models.categories);
      controller.set('projects', models.projects);
      controller.set('series', models.series);
      controller.set('institutions', models.departments);
      models.departments.forEach(function (dep) {
        if (dep.name === 'Extern institution') {
          controller.set('defaultInstitution', dep);
        }
      });
      controller.set('languages', models.languages);
      controller.set('publicationIdentifierCodes', models.publicationIdentifierCodes);
      controller.set('publicationTypes', models.publicationTypes);
      controller.set('refValueBool', models.publication.ref_value === 'ISREF');
      controller.set('artworkTypes', models.artworkTypes);

      var authors = null;
      if (models.publication) {
        if (models.publication.authors) {
          if (models.publication.authors.length > 0) {
            authors = models.publication.authors;
          }
          controller.set('arrOfAuthorsFromImport', models.publication.authors_from_import);
        }
      }
      var tempAuthorArr = [];
      if (authors) {
        authors.forEach(function (author) {
          var departments = [];
          author.departments.forEach(function (department) {
            departments.push(_ember['default'].Object.create({
              id: department.id,
              name: department.name
            }));
          });
          tempAuthorArr.push(_ember['default'].Object.create({
            id: author.id,
            selectedAuthor: {
              id: author.id,
              presentation_string: author.presentation_string,
              last_name: author.last_name
            },
            selectedInstitution: departments
          }));
        });
        controller.set('authorArr', tempAuthorArr);
      }

      var publicationType = models.publicationTypes.findBy('id', models.publication.publication_type_id);
      if (publicationType) {
        controller.set('selectedPublicationType', publicationType.code);
      } else {
        controller.set('selectedPublicationType', null);
      }
      if (models.publication.publication_type_suggestion) {
        //controller.set('mayBecomeSelectedPublicationType', models.publication.publication_type_suggestion);
        controller.set('suggestedPublicationType', models.publication.publication_type_suggestion);
      } else {
        // This needs to be reset if no suggestion was found, so that any previous suggestion is removed
        controller.set('suggestedPublicationType', null);
      }
      controller.set('manageController.isNavVisible', false);
    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        // @TODO: replace exit-hook with this?
      }
    },
    exit: function exit() {
      var controller = this.get('controller');
      //TODO: institutions?
      controller.set('selectedPublicationType', null);
      controller.set('authorArr', []);
      controller.set('mayBecomeSelectedPublicationType', null);
      controller.set('mayBecomeOldSelectedPublicationType', null);
      controller.set('errors', null);
      //TODO: temporary fix, this sucks:
      controller.set('categoryObjectsList', undefined);
    },
    returnToArguments: _ember['default'].computed('returnTo', 'returnToModels', 'returnToQueryParams', function () {
      var args = [this.get('returnTo')];
      if (_ember['default'].isPresent(this.get('returnToModels'))) {
        args.pushObject({ queryParams: this.get('returnToModels') });
      }
      if (_ember['default'].isPresent(this.get('returnToQueryParams'))) {
        args.pushObject({ queryParams: this.get('returnToQueryParams') });
      }
      return args;
    }),
    hasReturnTo: _ember['default'].computed.notEmpty('returnTo'),
    actions: {
      willTransition: function willTransition() {
        this.send('refreshModel', this.controller.get('publication.id'));
      },
      cancelEdit: function cancelEdit() {
        if (this.get('hasReturnTo')) {
          this.transitionTo.apply(this, _toConsumableArray(this.get('returnToArguments')));
        } else if (this.get('controller').get('publication.process_state') === 'PREDRAFT') {
          this.transitionTo('publications.dashboard.manage.published');
        } else {
          this.transitionTo('publications.dashboard.manage.show', this.controller.get('publication.id'));
        }
      },
      // TODO: this should probably live in the controller?
      savePublication: function savePublication(isDraft) {
        var _this = this;

        if (!isDraft) {
          // check to see if trying to save a publication with no affiliations
          var authors = this.get('controller.authorArr');
          var authorAffiliated = authors.find(function (author) {
            var validInstitutions = author.get("selectedInstitution").filter(function (item) {
              return item.id !== 666;
            });
            return validInstitutions.length > 0;
          });
          if (!authorAffiliated) {
            var continueSave = confirm(this.get('i18n').t('publications.dashboard.manage.show.edit.confirm'));
            if (!continueSave) {
              return;
            }
          }
        }
        //TODO: Ok solution for now, can be solved more elegantly?
        this.set('controller.publication.publication_links', this.get('controller.publication.publication_links').filter(function (link) {
          return _ember['default'].isPresent(link.get('url'));
        }));

        //TODO: OCD fix to prevent position gaps, later: refactor component to not use (position) interally and just set it here
        this.get('controller.publication.publication_links').sortBy('position').forEach(function (link, index) {
          link.set('position', index);
        });

        var savePublication = new _ember['default'].RSVP.Promise(function (resolve, reject) {

          var successHandler = function successHandler(model) {
            var message = isDraft ? _this.get('i18n').t('publications.dashboard.manage.show.edit.saveDraftSuccess') : _this.get('i18n').t('publications.dashboard.manage.show.edit.publishSuccess');
            _this.send('setMsgHeader', 'success', message);
            _this.send('refreshModel', model.id);
            //TODO: Check why we do this, also on draft?
            if (!isDraft) {
              _this.send('refreshUserdata');
            }

            _this.get('hasReturnTo') ? _this.transitionTo.apply(_this, _toConsumableArray(_this.get('returnToArguments'))) : _this.transitionTo('publications.dashboard.manage.show', model.id);
            resolve();
          };

          var errorHandler = function errorHandler(errors) {

            // If js error
            if (typeof errors === 'object' && 'message' in errors) {
              errors = errors.message;
            }

            if (typeof errors == 'string') {
              _this.send('setMsgHeader', 'error', errors);
            } else {
              // Assume errors = model.error.errors
              _this.controller.set('errors', errors);
            }

            if (!isDraft && _this.controller.get('publication.draft_id')) {
              _this.controller.set('publication.id', _this.controller.get('publication.draft_id'));
              _this.controller.set('publication.draft_id', null);
            }

            _ember['default'].run.schedule('afterRender', function () {
              _ember['default'].$('[data-toggle="popover"]').popover({
                placement: 'top',
                html: true
              });
            });
            reject();
          };

          //TODO: remove submitCallbacksRun and use .all here instead for clarity?
          _this.get('controller').submitCallbacksRun().then(function () {
            if (!isDraft && !_this.controller.get('publication.published_at')) {
              _this.controller.set('publication.draft_id', _this.controller.get('publication.id'));
              _this.controller.set('publication.id', null);
            }
            //TODO: consisistent use of either this.get('controller') or this.controller! Which is correct?
            _this.get('controller').formatAuthorsForServer();
            var resource = isDraft ? 'draft' : 'published_publication';
            _this.store.save(resource, _this.controller.get('publication')).then(function (model) {
              if (!model) {
                errorHandler(_this.get('i18n').t('publications.dashboard.manage.show.edit.systemError'));
              } else if (model.error) {
                return errorHandler(model.error.errors);
              } else {
                return successHandler(model);
              }
            }, errorHandler);
          }, errorHandler);
        });
        this.send('pageIsDisabled', savePublication);
      }
    }
  });
});