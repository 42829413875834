define('frontend/controllers/publications/dashboard/biblreview', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    publicationsController: _ember['default'].inject.controller('publications'),
    pubyear: 0,
    pubtype: null,
    faculty: null,
    page: 1,
    queryParams: ['faculty', 'pubyear', 'pubtype', 'page', 'only_delayed'],
    only_delayed: false,
    pubyears: _ember['default'].computed(function () {
      return [{ pubyear: this.get('i18n').t('publications.dashboard.biblreview.selectPublicationYearPrompt'), id: 0 }, { pubyear: (0, _moment['default'])().year() + ' ' + this.get('i18n').t('publications.dashboard.biblreview.orLater'), id: 1 }, { pubyear: (0, _moment['default'])().year() - 1, id: (0, _moment['default'])().year() - 1 }, { pubyear: (0, _moment['default'])().year() - 2, id: (0, _moment['default'])().year() - 2 }, { pubyear: (0, _moment['default'])().year() - 3, id: (0, _moment['default'])().year() - 3 }];
    }),

    // Id must be a string to be compared with a value from query params
    faculties: _ember['default'].computed('publicationsController.faculties', function () {
      return this.get('publicationsController.faculties').map(function (item) {
        return { id: '' + item.id, name: item.name };
      });
    }),
    pubtypes: _ember['default'].computed('publicationsController.publicationTypes', function () {
      return this.get('publicationsController.publicationTypes').map(function (item) {
        return { id: '' + item.id, name: item.name };
      });
    })
  });
});