define('frontend/routes/admin/departments/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('admin.departments.title');
    },
    queryParams: {
      qd: { refreshModel: true }
    },
    model: function model(params) {
      return this.store.find("department", { search_term: params.qd });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      sessionStorage.setItem('admin.departments.lastQuery', controller.get('qd'));
      controller.set('query', controller.get('qd'));
    },
    actions: {
      setEndYear: function setEndYear(model, newEndYear) {
        var _this = this;

        if (newEndYear) {
          _ember['default'].set(model, 'end_year', parseInt(newEndYear));
        } else {
          _ember['default'].set(model, 'end_year', null);
        }
        this.store.save('department', model).then(function () {
          _ember['default'].$('#setEndYearModal').modal('hide');
        }, function () {
          _ember['default'].set(model, 'end_year', null);
          _this.controller.set('modalError', _this.get('i18n').t('admin.departments.index.saveError'));
        });
      }
    }
  });
});