define('frontend/routes/publications/dashboard/manage/file-imports', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('publications.dashboard.manage.fileImports.title');
    },
    beforeModel: function beforeModel(transition) {
      this.set('isShowingRecordId', transition.queryParams.isShowingRecordId);
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);
      controller.set("manageController.isNavVisible", true);
      controller.set('fileImportActiveItems', []);
      if (_ember['default'].isPresent(this.get('isShowingRecordId'))) {
        (function () {
          var isShowingRecordId = parseInt(_this.get('isShowingRecordId'));
          // Or set this only if publication_id set to avoid extra logic in template?
          controller.set('isShowingRecordId', isShowingRecordId);
          var expandedFileImport = model.find(function (fileImport) {
            var record = fileImport.get('endnote_records').findBy('id', isShowingRecordId);
            // Only exand and highlight record if a draft/publication was actually created
            // not if used cancelled (predraft)
            // TODO: Convert to ember object in model()?
            return record !== undefined && _ember['default'].isPresent(record.publication_id);
          });
          if (_ember['default'].isPresent(expandedFileImport)) {
            controller.get('fileImportActiveItems').pushObject(expandedFileImport);
          }
        })();
      }
    },
    model: function model() {
      return this.store.find('endnote_file').then(function (data) {
        return data.map(function (endnote_file) {
          return _ember['default'].Object.create(endnote_file);
        });
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});