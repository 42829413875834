define("frontend/templates/publications/dashboard/manage/show/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-12");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "no-result", [], ["icon", "fa-frown-o", "displaytext", ["subexpr", "t", ["publications.dashboard.manage.show.index.errorMissingPublication"], [], ["loc", [null, [4, 46], [4, 116]]], 0, 0]], ["loc", [null, [4, 4], [4, 118]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 2
              },
              "end": {
                "line": 16,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-8 col-xs-offset-2");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "alert alert-info");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4, "href", "javascript.void(0)");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("i");
            dom.setAttribute(el5, "class", "fa fa-compress");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1, 1, 1]);
            var element12 = dom.childAt(element11, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element11, 1, 1);
            morphs[1] = dom.createElementMorph(element12);
            morphs[2] = dom.createMorphAt(element12, 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["publications.dashboard.manage.show.index.reviewModeInfo"], [], ["loc", [null, [12, 10], [12, 73]]], 0, 0], ["element", "action", ["toggleViewMode"], [], ["loc", [null, [12, 103], [12, 130]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index.reviewModeCancel"], [], ["loc", [null, [12, 131], [12, 196]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 14
              },
              "end": {
                "line": 24,
                "column": 159
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-link");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["publications.dashboard.manage.show.index._subnav.permalink"], [], ["loc", [null, [24, 92], [24, 158]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 14
              },
              "end": {
                "line": 25,
                "column": 166
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-pencil");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["publications.dashboard.manage.show.index._subnav.edit"], [], ["loc", [null, [25, 104], [25, 165]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 10
              },
              "end": {
                "line": 28,
                "column": 10
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "javascript:void();");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-trash");
            dom.setAttribute(el3, "title", "");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1, 0]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element10);
            morphs[1] = dom.createMorphAt(element10, 2, 2);
            return morphs;
          },
          statements: [["element", "action", ["deletePublication", ["get", "model.id", ["loc", [null, [27, 74], [27, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 45], [27, 84]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index._subnav.delete"], [], ["loc", [null, [27, 122], [27, 185]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 10
              },
              "end": {
                "line": 31,
                "column": 10
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "mailer-modal", [], ["publication", ["subexpr", "@mut", [["get", "model", ["loc", [null, [30, 43], [30, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 16], [30, 50]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 35,
                      "column": 16
                    },
                    "end": {
                      "line": 38,
                      "column": 16
                    }
                  },
                  "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2, "href", "javascript:void();");
                  var el3 = dom.createElement("i");
                  dom.setAttribute(el3, "class", "fa fa-check");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(" ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [3, 0]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  morphs[1] = dom.createElementMorph(element9);
                  morphs[2] = dom.createMorphAt(element9, 2, 2);
                  return morphs;
                },
                statements: [["inline", "delay-review-modal", [], ["publication", ["subexpr", "@mut", [["get", "model", ["loc", [null, [36, 55], [36, 60]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 22], [36, 62]]], 0, 0], ["element", "action", ["approvePublication", ["get", "model.id", ["loc", [null, [37, 81], [37, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 51], [37, 91]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index._subnav.biblreview"], [], ["loc", [null, [37, 120], [37, 187]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 14
                  },
                  "end": {
                    "line": 39,
                    "column": 14
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "model.biblreviewed_at", ["loc", [null, [35, 26], [35, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [35, 16], [38, 27]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 12
                },
                "end": {
                  "line": 40,
                  "column": 12
                }
              },
              "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "model.published_at", ["loc", [null, [34, 20], [34, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 14], [39, 21]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 10
              },
              "end": {
                "line": 41,
                "column": 10
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "session.data.authenticated.can_biblreview", ["loc", [null, [33, 18], [33, 59]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [33, 12], [40, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 47,
                    "column": 12
                  },
                  "end": {
                    "line": 51,
                    "column": 12
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "href", "javascript.void(0)");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "fa fa-compress");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element7);
                morphs[1] = dom.createMorphAt(element7, 3, 3);
                return morphs;
              },
              statements: [["element", "action", ["toggleCompareMode"], [], ["loc", [null, [49, 45], [49, 75]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index._subnav.reviewMode"], [], ["loc", [null, [49, 108], [49, 175]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 12
                  },
                  "end": {
                    "line": 55,
                    "column": 12
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "href", "javascript.void(0)");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "fa fa-compress");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element6);
                morphs[1] = dom.createMorphAt(element6, 3, 3);
                return morphs;
              },
              statements: [["element", "action", ["toggleCompareMode"], [], ["loc", [null, [53, 45], [53, 75]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index._subnav.compare"], [], ["loc", [null, [53, 108], [53, 172]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 14
                },
                "end": {
                  "line": 56,
                  "column": 14
                }
              },
              "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "javascript.void(0)");
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-compress");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element8);
              morphs[1] = dom.createMorphAt(element8, 3, 3);
              morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["element", "action", ["toggleViewMode"], [], ["loc", [null, [45, 45], [45, 72]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index._subnav.viewMode"], [], ["loc", [null, [45, 105], [45, 170]]], 0, 0], ["block", "if", [["get", "isExtendedCompareMode", ["loc", [null, [47, 18], [47, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [47, 12], [55, 19]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 14
                },
                "end": {
                  "line": 60,
                  "column": 14
                }
              },
              "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "javascript.void(0)");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-expand");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element5);
              morphs[1] = dom.createMorphAt(element5, 2, 2);
              return morphs;
            },
            statements: [["element", "action", ["toggleViewMode"], [], ["loc", [null, [58, 45], [58, 72]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index._subnav.reviewMode"], [], ["loc", [null, [58, 102], [58, 169]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 10
              },
              "end": {
                "line": 61,
                "column": 10
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isExtendedViewMode", ["loc", [null, [43, 20], [43, 38]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [43, 14], [60, 21]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child7 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 70,
                  "column": 8
                },
                "end": {
                  "line": 72,
                  "column": 8
                }
              },
              "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "publication-display", [], ["publication", ["subexpr", "@mut", [["get", "model", ["loc", [null, [71, 42], [71, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "publicationType", ["subexpr", "@mut", [["get", "getPublicationTypeObject", ["loc", [null, [71, 64], [71, 88]]], 0, 0, 0, 0]], [], [], 0, 0], "allPublicationTypes", ["subexpr", "@mut", [["get", "getAllPublicationTypes", ["loc", [null, [71, 109], [71, 131]]], 0, 0, 0, 0]], [], [], 0, 0], "mode", "compare", "otherPublication", ["subexpr", "@mut", [["get", "otherPublication", ["loc", [null, [71, 164], [71, 180]]], 0, 0, 0, 0]], [], [], 0, 0], "otherIsSelected", ["subexpr", "@mut", [["get", "otherPublicationSelected", ["loc", [null, [71, 197], [71, 221]]], 0, 0, 0, 0]], [], [], 0, 0], "other_version", ["subexpr", "@mut", [["get", "other_version", ["loc", [null, [71, 236], [71, 249]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [71, 8], [71, 251]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 72,
                  "column": 8
                },
                "end": {
                  "line": 74,
                  "column": 8
                }
              },
              "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "publication-display", [], ["publication", ["subexpr", "@mut", [["get", "model", ["loc", [null, [73, 42], [73, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "allPublicationTypes", ["subexpr", "@mut", [["get", "getAllPublicationTypes", ["loc", [null, [73, 68], [73, 90]]], 0, 0, 0, 0]], [], [], 0, 0], "publicationType", ["subexpr", "@mut", [["get", "getPublicationTypeObject", ["loc", [null, [73, 107], [73, 131]]], 0, 0, 0, 0]], [], [], 0, 0], "mode", "review"], ["loc", [null, [73, 8], [73, 147]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 83,
                    "column": 10
                  },
                  "end": {
                    "line": 83,
                    "column": 149
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.updatedAt"], [], ["loc", [null, [83, 40], [83, 103]]], 0, 0], ["inline", "format-date", [["get", "model.updated_at", ["loc", [null, [83, 126], [83, 142]]], 0, 0, 0, 0]], [], ["loc", [null, [83, 112], [83, 144]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 84,
                    "column": 12
                  },
                  "end": {
                    "line": 84,
                    "column": 153
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.updatedBy"], [], ["loc", [null, [84, 48], [84, 111]]], 0, 0], ["content", "model.version_updated_by", ["loc", [null, [84, 120], [84, 148]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 85,
                    "column": 14
                  },
                  "end": {
                    "line": 85,
                    "column": 157
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.publishedAt"], [], ["loc", [null, [85, 44], [85, 109]]], 0, 0], ["inline", "format-date", [["get", "model.published_at", ["loc", [null, [85, 132], [85, 150]]], 0, 0, 0, 0]], [], ["loc", [null, [85, 118], [85, 152]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 86,
                    "column": 16
                  },
                  "end": {
                    "line": 86,
                    "column": 189
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.reviewStart"], [], ["loc", [null, [86, 60], [86, 125]]], 0, 0], ["inline", "format-date", [["get", "model.biblreview_postponed_until", ["loc", [null, [86, 150], [86, 182]]], 0, 0, 0, 0]], [], ["loc", [null, [86, 136], [86, 184]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 18
                  },
                  "end": {
                    "line": 87,
                    "column": 182
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.delayComment"], [], ["loc", [null, [87, 64], [87, 130]]], 0, 0], ["content", "model.biblreview_postponed_comment", ["loc", [null, [87, 139], [87, 177]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 88,
                    "column": 20
                  },
                  "end": {
                    "line": 88,
                    "column": 129
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publication.labels.reviewedBy"], [], ["loc", [null, [88, 53], [88, 90]]], 0, 0], ["content", "model.biblreviewed_by", ["loc", [null, [88, 99], [88, 124]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 89,
                    "column": 22
                  },
                  "end": {
                    "line": 89,
                    "column": 170
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.reviewedAt"], [], ["loc", [null, [89, 55], [89, 119]]], 0, 0], ["inline", "format-date", [["get", "model.biblreviewed_at", ["loc", [null, [89, 142], [89, 163]]], 0, 0, 0, 0]], [], ["loc", [null, [89, 128], [89, 165]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 10
                },
                "end": {
                  "line": 90,
                  "column": 22
                }
              },
              "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("dt");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("dd");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("dt");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("dd");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(11);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(fragment, [5]), 0, 0);
              morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 11, 11, contextualElement);
              morphs[7] = dom.createMorphAt(fragment, 13, 13, contextualElement);
              morphs[8] = dom.createMorphAt(fragment, 15, 15, contextualElement);
              morphs[9] = dom.createMorphAt(fragment, 17, 17, contextualElement);
              morphs[10] = dom.createMorphAt(fragment, 19, 19, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["publication.labels.createdAt"], [], ["loc", [null, [81, 14], [81, 50]]], 0, 0], ["inline", "format-date", [["get", "model.created_at", ["loc", [null, [81, 73], [81, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [81, 59], [81, 91]]], 0, 0], ["inline", "t", ["publication.labels.createdBy"], [], ["loc", [null, [82, 14], [82, 50]]], 0, 0], ["content", "model.version_created_by", ["loc", [null, [82, 59], [82, 87]]], 0, 0, 0, 0], ["block", "if", [["get", "model.published_at", ["loc", [null, [83, 16], [83, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [83, 10], [83, 156]]]], ["block", "if", [["get", "model.version_updated_by", ["loc", [null, [84, 18], [84, 42]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [84, 12], [84, 160]]]], ["block", "if", [["get", "model.published_at", ["loc", [null, [85, 20], [85, 38]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [85, 14], [85, 164]]]], ["block", "if", [["get", "model.biblreview_postponed_until", ["loc", [null, [86, 22], [86, 54]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [86, 16], [86, 196]]]], ["block", "if", [["get", "model.biblreview_postponed_comment", ["loc", [null, [87, 24], [87, 58]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [87, 18], [87, 189]]]], ["block", "if", [["get", "model.biblreviewed_by", ["loc", [null, [88, 26], [88, 47]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [88, 20], [88, 136]]]], ["block", "if", [["get", "model.biblreviewed_at", ["loc", [null, [89, 28], [89, 49]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [89, 22], [89, 177]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 93,
                    "column": 24
                  },
                  "end": {
                    "line": 96,
                    "column": 24
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [0]), 0, 0);
                morphs[2] = dom.createMorphAt(element3, 2, 2);
                morphs[3] = dom.createMorphAt(dom.childAt(element3, [4]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.updatedAt"], [], ["loc", [null, [94, 30], [94, 93]]], 0, 0], ["inline", "format-date", [["get", "model.updated_at", ["loc", [null, [95, 50], [95, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [95, 36], [95, 68]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index.meta.by"], [], ["loc", [null, [95, 76], [95, 132]]], 0, 0], ["content", "model.version_updated_by", ["loc", [null, [95, 139], [95, 167]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 97,
                    "column": 24
                  },
                  "end": {
                    "line": 100,
                    "column": 24
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
                morphs[2] = dom.createMorphAt(element2, 2, 2);
                morphs[3] = dom.createMorphAt(dom.childAt(element2, [4]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.publishedAt"], [], ["loc", [null, [98, 30], [98, 95]]], 0, 0], ["inline", "format-date", [["get", "model.published_at", ["loc", [null, [99, 50], [99, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [99, 36], [99, 70]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index.meta.by"], [], ["loc", [null, [99, 78], [99, 134]]], 0, 0], ["content", "model.version_created_by", ["loc", [null, [99, 142], [99, 170]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 104,
                      "column": 26
                    },
                    "end": {
                      "line": 106,
                      "column": 26
                    }
                  },
                  "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            (");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(")\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "model.biblreview_postpone_comment", ["loc", [null, [105, 29], [105, 66]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 101,
                    "column": 24
                  },
                  "end": {
                    "line": 108,
                    "column": 24
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [3]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
                morphs[2] = dom.createMorphAt(element1, 2, 2);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.reviewPostponedUntil"], [], ["loc", [null, [102, 30], [102, 104]]], 0, 0], ["inline", "format-date", [["get", "model.biblreview_postponed_until", ["loc", [null, [103, 50], [103, 82]]], 0, 0, 0, 0], "YYYY-MM-DD"], [], ["loc", [null, [103, 36], [103, 97]]], 0, 0], ["block", "if", [["get", "model.biblreview_postpone_comment", ["loc", [null, [104, 32], [104, 65]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [104, 26], [106, 33]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 109,
                    "column": 24
                  },
                  "end": {
                    "line": 112,
                    "column": 24
                  }
                },
                "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dt");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("dd");
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [3]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
                morphs[2] = dom.createMorphAt(element0, 2, 2);
                morphs[3] = dom.createMorphAt(dom.childAt(element0, [4]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.reviewedAt"], [], ["loc", [null, [110, 30], [110, 94]]], 0, 0], ["inline", "format-date", [["get", "model.biblreviewed_at", ["loc", [null, [111, 50], [111, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [111, 36], [111, 73]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index.meta.by"], [], ["loc", [null, [111, 81], [111, 137]]], 0, 0], ["content", "model.biblreviewed_by", ["loc", [null, [111, 145], [111, 170]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 90,
                  "column": 22
                },
                "end": {
                  "line": 113,
                  "column": 10
                }
              },
              "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("dt");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("dd");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [3]);
              var morphs = new Array(8);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [0]), 0, 0);
              morphs[2] = dom.createMorphAt(element4, 2, 2);
              morphs[3] = dom.createMorphAt(dom.childAt(element4, [4]), 0, 0);
              morphs[4] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[7] = dom.createMorphAt(fragment, 8, 8, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["publications.dashboard.manage.show.index.meta.createdAt"], [], ["loc", [null, [91, 28], [91, 91]]], 0, 0], ["inline", "format-date", [["get", "model.created_at", ["loc", [null, [92, 48], [92, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [92, 34], [92, 66]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index.meta.by"], [], ["loc", [null, [92, 74], [92, 130]]], 0, 0], ["content", "model.version_created_by", ["loc", [null, [92, 138], [92, 166]]], 0, 0, 0, 0], ["block", "if", [["get", "model.updated_at", ["loc", [null, [93, 30], [93, 46]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [93, 24], [96, 31]]]], ["block", "if", [["get", "model.published_at", ["loc", [null, [97, 30], [97, 48]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [97, 24], [100, 31]]]], ["block", "if", [["get", "model.biblreview_postponed_until", ["loc", [null, [101, 30], [101, 62]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [101, 24], [108, 31]]]], ["block", "if", [["get", "model.biblreviewed_at", ["loc", [null, [109, 30], [109, 51]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [109, 24], [112, 31]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 2
              },
              "end": {
                "line": 117,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-10");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("dl");
            dom.setAttribute(el3, "class", "dl-horizontal dl-biblreview");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "isExtendedCompareMode", ["loc", [null, [70, 14], [70, 35]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [70, 8], [74, 15]]]], ["block", "if", [0], [], 2, 3, ["loc", [null, [80, 10], [113, 17]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      var child8 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 117,
                "column": 2
              },
              "end": {
                "line": 123,
                "column": 2
              }
            },
            "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "publication-display", [], ["publication", ["subexpr", "@mut", [["get", "model", ["loc", [null, [120, 42], [120, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "allPublicationTypes", ["subexpr", "@mut", [["get", "getAllPublicationTypes", ["loc", [null, [120, 68], [120, 90]]], 0, 0, 0, 0]], [], [], 0, 0], "publicationType", ["subexpr", "@mut", [["get", "getPublicationTypeObject", ["loc", [null, [120, 107], [120, 131]]], 0, 0, 0, 0]], [], [], 0, 0], "mode", "preview"], ["loc", [null, [120, 8], [120, 148]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 125,
              "column": 0
            }
          },
          "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-3");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "javascript:void();");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "fa fa-chevron-left");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-9");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("nav");
          dom.setAttribute(el3, "id", "publication-toolbar");
          dom.setAttribute(el3, "class", "pull-right");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4, "class", "list-inline");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [2]);
          var element14 = dom.childAt(element13, [1, 1]);
          var element15 = dom.childAt(element13, [3, 1, 1]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createElementMorph(element14);
          morphs[2] = dom.createMorphAt(element14, 2, 2);
          morphs[3] = dom.createMorphAt(dom.childAt(element15, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element15, [3]), 0, 0);
          morphs[5] = dom.createMorphAt(element15, 5, 5);
          morphs[6] = dom.createMorphAt(element15, 6, 6);
          morphs[7] = dom.createMorphAt(element15, 7, 7);
          morphs[8] = dom.createMorphAt(element15, 8, 8);
          morphs[9] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "isExtendedViewMode", ["loc", [null, [8, 8], [8, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 2], [16, 9]]]], ["element", "action", ["goBack"], [], ["loc", [null, [19, 35], [19, 54]]], 0, 0], ["inline", "t", ["publications.dashboard.manage.show.index.btnBack"], [], ["loc", [null, [19, 90], [19, 146]]], 0, 0], ["block", "link-to", ["publication", ["get", "model.id", ["loc", [null, [24, 39], [24, 47]]], 0, 0, 0, 0]], ["target", "_blank"], 1, null, ["loc", [null, [24, 14], [24, 171]]]], ["block", "link-to", ["publications.dashboard.manage.show.edit", ["get", "model", ["loc", [null, [25, 67], [25, 72]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [25, 14], [25, 178]]]], ["block", "if", [["get", "session.data.authenticated.can_delete_published", ["loc", [null, [26, 16], [26, 63]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [26, 10], [28, 17]]]], ["block", "unless", [["get", "session.data.authenticated.can_biblreview", ["loc", [null, [29, 20], [29, 61]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [29, 10], [31, 21]]]], ["block", "if", [["get", "isExtendedViewMode", ["loc", [null, [32, 16], [32, 34]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [32, 10], [41, 17]]]], ["block", "if", [["get", "session.data.authenticated.can_biblreview", ["loc", [null, [42, 16], [42, 57]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [42, 10], [61, 17]]]], ["block", "if", [["get", "isExtendedViewMode", ["loc", [null, [67, 8], [67, 26]]], 0, 0, 0, 0]], [], 7, 8, ["loc", [null, [67, 2], [123, 9]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 129,
            "column": 0
          }
        },
        "moduleName": "frontend/templates/publications/dashboard/manage/show/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "error", ["loc", [null, [1, 6], [1, 11]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [125, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});