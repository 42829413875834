define('frontend/controllers/publications/dashboard/reports', ['exports', 'ember', 'frontend/config/environment', 'frontend/lib/validations', 'moment'], function (exports, _ember, _frontendConfigEnvironment, _frontendLibValidations, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    publicationsController: _ember['default'].inject.controller('publications'),
    publicationTypes: [],
    person: null,
    columns: {},
    filter: {},
    model: null,

    yearRangeDepartments: _ember['default'].computed('filter.{start_year,end_year}', function () {
      var startYear = this.get('filter.start_year') || '';
      var endYear = this.get('filter.end_year') || '';
      var validStartYear = (0, _frontendLibValidations.validYear)(startYear);
      var validEndYear = (0, _frontendLibValidations.validYear)(endYear);
      var departments = this.get('departments');
      if (validStartYear) {
        startYear = parseInt(startYear);
        departments = departments.filter(function (item) {
          return !_ember['default'].isPresent(item.end_year) || item.end_year >= startYear;
        });
      }
      if (validEndYear) {
        endYear = parseInt(endYear);
        departments = departments.filter(function (item) {
          return !_ember['default'].isPresent(item.start_year) || item.start_year <= endYear;
        });
      }
      return departments;
    }),

    selectableDepartments: _ember['default'].computed('yearRangeDepartments', 'filter.{faculties}', function () {
      var _this = this;

      var departments = this.get('yearRangeDepartments');
      departments.forEach(function (item) {
        item.children = null;
      });

      // remove here
      if (_ember['default'].isPresent(this.get('filter.faculties'))) {
        (function () {
          var facultyId = _this.get('filter.faculties');
          departments = departments.filter(function (item) {
            return facultyId === item.faculty_id;
          });
        })();
      }

      if (_ember['default'].isPresent(this.get('filter.departments'))) {
        var selectable_selected_departments = this.get('filter.departments').filter(function (department_id) {
          return departments.findBy('id', department_id);
        });
        //If one or more selected department no longer among selectable, set new valid filtered selection
        if (this.get('filter.departments').length !== selectable_selected_departments.length) {
          this.set('filter.departments', selectable_selected_departments);
        }
      }
      return departments;
    }),

    columnArray: _ember['default'].computed('columns.{year,faculty,department,publication_type,ref_value}', function () {
      var cArray = [];
      if (this.get('columns.year')) {
        cArray.push('year');
      }
      if (this.get('columns.faculty')) {
        cArray.push('faculty_id');
      }
      if (this.get('columns.department')) {
        cArray.push('department_id');
      }
      if (this.get('columns.publication_type')) {
        cArray.push('publication_type_id');
      }
      if (this.get('columns.ref_value')) {
        cArray.push('ref_value');
      }
      return cArray;
    }),

    filterData: _ember['default'].computed('filter.{start_year,end_year,faculties,departments,publication_types,ref_value}', 'publicationTypes.@each.checked', 'person.identifiers', function () {
      var that = this;
      var publication_types = [];
      this.get('publicationTypes').forEach(function (item) {
        if (item.checked) {
          publication_types.push(item.code);
        }
      });
      this.set('filter.publication_types', publication_types);
      if (this.get('person.identifiers')) {
        this.get('person.identifiers').forEach(function (identifier) {
          that.set('filter.persons', [identifier.value]);
        });
      }
      return this.get('filter');
    }),

    hasFilterData: _ember['default'].computed('filterData', function () {
      var filters = ['start_year', 'end_year', 'faculties', 'departments', 'publication_types', 'ref_value'];
      for (var i in filters) {
        if (_ember['default'].isPresent(this.get('filterData.' + filters[i]))) {
          return true;
        }
      }
      return false;
    }),

    csvUrl: _ember['default'].computed('columnArray', 'filterData', function () {
      var that = this;
      var date = (0, _moment['default'])();
      var report_name = "report-" + date.format("YYYY-MM-DD_HHMM");
      var token = this.get("session.data.authenticated.token");
      var report_data = _ember['default'].$.param({
        report: {
          filter: that.get('filterData'),
          columns: that.get('columnArray')
        }
      });
      return _frontendConfigEnvironment['default'].APP.serviceURL + '/reports/' + report_name + '?token=' + token + '&' + report_data;
    }),

    nonColumnFilters: _ember['default'].computed('filterData', 'reportRowsColumns', function () {
      var filters = {};
      var columns = this.get('reportRowsColumns');
      var filterData = this.get('filterData');

      // Include year filters if year grouping not selected
      if (columns.indexOf('year') === -1) {
        if (_ember['default'].isPresent(filterData.start_year)) {
          filters.start_year = filterData.start_year;
        }
        if (_ember['default'].isPresent(filterData.end_year)) {
          filters.end_year = filterData.end_year;
        }
      }
      if (columns.indexOf('faculty_id') === -1 && _ember['default'].isPresent(filterData.faculties)) {
        filters.faculty_id = filterData.faculties;
      }
      if (columns.indexOf('department_id') === -1 && _ember['default'].isPresent(filterData.departments)) {
        filters.department_id = filterData.departments;
      }
      return filters;
    }),

    hasNonColumnFilters: _ember['default'].computed.notEmpty('nonColumnFilters'),

    reportRows: _ember['default'].computed('model.data[]', 'reportRowsColumns.[]', function () {
      //reportRowsColumns.[] can be removed?
      var rows = this.get('model.data');

      if (_ember['default'].isEmpty(rows)) {
        return [];
      }
      var token = this.get('session.data.authenticated.token');
      // @TODO: Use Ember.$.param instead
      var url = _frontendConfigEnvironment['default'].APP.serviceURL + '/published_publications_xls/?token=' + token;
      var columns = this.get('reportRowsColumns');
      // @TODO: put this somewhere else, or use lodash zipObject(?) "https://lodash.com/"
      /*
      let zipObject = function(keys, values) {
        return keys.reduce(function(result, key, index) {
          result[key] = values[index];
          return result;
        }, {});
      };
      */
      if (this.get('hasNonColumnFilters')) {
        url += '&' + _ember['default'].$.param(this.get('nonColumnFilters'));
      }
      // @TODO: filter data array and exclude??
      return rows.map(function (row, rowIndex) {
        var rowObject = _ember['default'].Object.create();
        rowObject.set('columns', row.map(function (item) {
          return _ember['default'].typeOf(item) === 'array' ? item[0] : item;
        }));
        var columnsQuery = columns.reduce(function (query, column, index) {
          var colValue = row[index];
          // @TODO: Use Ember.$.param instead
          query += '&' + column + '=' + (_ember['default'].typeOf(colValue) === 'array' ? colValue[1] : colValue);
          return query;
        }, '');
        rowObject.set('xls_url', url + columnsQuery + '&name_suffix=_' + row.get('lastObject') + '_' + rowIndex);
        return rowObject;
      });
    }),
    hasReportRows: _ember['default'].computed.notEmpty('reportRows'),
    //Somehow this messes with vim auto-indent :(, so putting it last
    selectableFaculties: _ember['default'].computed('yearRangeDepartments', 'publicationsController.faculties', function () {
      // @TODO: this could be computed prop for increased performance
      var facultyIds = this.get('yearRangeDepartments').reduce(function (result, department) {
        result[department.faculty_id] = department.faculty_id;
        return result;
      }, []);
      var faculties = this.get('publicationsController.faculties');
      return facultyIds.map(function (id) {
        //TODO: this could be made much faster by indexing faculties by id instead
        return faculties.findBy('id', id) || _ember['default'].Object.create({
          id: id,
          name: 'Unknown/Extern (id: ' + id + ')'
        });
      });
    })
  });
});