define('frontend/components/multiple-items', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    createNewItem: null,
    addItemText: null,
    deleteItemText: null,
    items: null,
    sortedItems: _ember['default'].computed('items.@each.position', function () {
      return this.get('items').sortBy('position');
    }),

    totalNumberOfItems: _ember['default'].computed('items.[]', function () {
      //TODO: double check, array length javscript weirdness etc
      return this.get('items').length;
    }),

    //TODO: replace with Ember.Computed.(magick)
    hasMoreThanOneItem: _ember['default'].computed('items.[]', function () {
      //TODO: double check, array length javscript weirdness etc
      return this.get('items').length > 1;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (_ember['default'].isBlank(this.get('addItemText'))) {
        this.set('addItemText', this.get('i18n').t('components.multipleItems.addItemText'));
      }
      if (_ember['default'].isBlank(this.get('deleteItemText'))) {
        this.set('deleteItemText', this.get('i18n').t('components.multipleItems.deleteItemText'));
      }
      //TODO: Perhaps should expect parent components to provide items as array?
      //this.attrs.items.update(this.get('items'));
      if (_ember['default'].isEmpty(this.get('items'))) {
        this.set('items', _ember['default'].A([]));
      }
      if (!this.get('items').length) {
        this.send('addItem');
      }
    },
    actions: {
      //didAddItem etc??
      addItem: function addItem() {
        var newItem = this.get('createNewItem')();
        var lastItem = this.get('items').get('lastObject');
        newItem.set('position', lastItem !== undefined ? lastItem.get('position') + 1 : 0);
        this.get('items').pushObject(newItem);
      },
      removeItem: function removeItem(item) {
        this.get('items').removeObject(item);
      },
      didMoveItemUp: function didMoveItemUp(item) {
        var sortedItems = this.get('sortedItems');
        if (sortedItems.get('firstObject') !== item) {
          var idx = sortedItems.indexOf(item);
          var position = item.get('position');
          var itemAbove = sortedItems.objectAt(idx - 1);
          item.set('position', itemAbove.position);
          itemAbove.set('position', position);
        }
      },
      didMoveItemDown: function didMoveItemDown(item) {
        var sortedItems = this.get('sortedItems');
        if (sortedItems.get('lastObject') !== item) {
          var idx = sortedItems.indexOf(item);
          var position = item.get('position');
          var itemBelow = sortedItems.objectAt(idx + 1);
          item.set('position', itemBelow.position);
          itemBelow.set('position', position);
        }
      }
    }
  });
});