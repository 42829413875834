define('frontend/routes/publications/dashboard/manage/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

		beforeModel: function beforeModel() {
			this.transitionTo('publications.dashboard.manage.published');
		},

		setupController: function setupController(controller) {
			controller.set("isNavVisible", true);
		}
	});
});