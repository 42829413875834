define('frontend/components/publication-display', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    classNames: ['publication-display'],
    classNameBindings: ['isReviewMode:publication-review-list', 'isPreviewMode:publication-preview-list'],
    isReviewMode: _ember['default'].computed.equal('mode', 'review'),
    isPreviewMode: _ember['default'].computed.equal('mode', 'preview'),
    isCompareMode: _ember['default'].computed.equal('mode', 'compare'),

    getCodeForPublicationType: function getCodeForPublicationType(id) {
      var publicationType = this.get('allPublicationTypes').findBy('id', id);
      return publicationType.code;
    },

    getLowercaseRefValue: _ember['default'].computed('publication.ref_value_label', function () {
      return this.get('publication.ref_value_label').toLowerCase();
    }),

    getBaseUrlForIdentifier: _ember['default'].computed('publication.publication_identifiers', function () {
      //@ TODO: ??:
      /*
         let arr = this.get('publication.publication_identifiers');
         let baseDOI = 'https://doi.org/';
         let handle = 'http://hdl.handle.net/';
         let libris = 'http://libris.kb.se/bib/';
         let pubmed = 'https://www.ncbi.nlm.nih.gov/pubmed/';
         */
      return "baseUrl";
    }),

    getPublishedInStr: _ember['default'].computed('publication.id', function () {
      var arr = [];
      arr.push(this.get("publication.sourcetitle"));
      var sourceissue_str = "";
      if (this.get("publication.sourceissue")) {
        sourceissue_str = " (" + this.get("publication.sourceissue") + ")";
      }
      var sourceissue_and_pages_str = "";
      if (this.get("publication.sourcevolume")) {
        sourceissue_and_pages_str = this.get("publication.sourcevolume") + sourceissue_str;
      }
      if (sourceissue_and_pages_str !== "") {
        arr.push(sourceissue_and_pages_str);
      }
      arr.push(this.get("publication.sourcepages"));
      arr.push(this.get("publication.article_number"));
      return arr.compact().join(', ');
    }),

    isPatent: _ember['default'].computed('publication.publication_type', function () {
      var code = this.getCodeForPublicationType(this.get("publication.publication_type_id"));
      var arr = _ember['default'].A(['intellectual-property_patent']);
      return arr.indexOf(code) !== -1;
    }),

    isArt: _ember['default'].computed('publication.publication_type', function () {
      var code = this.getCodeForPublicationType(this.get("publication.publication_type_id"));
      var arr = _ember['default'].A(['artistic-work_scientific_and_development', 'artistic-work_original-creative-work']);
      return arr.indexOf(code) !== -1;
    }),

    isArticleConf: _ember['default'].computed('publication.publication_type', function () {
      var code = this.getCodeForPublicationType(this.get('publication.publication_type_id'));
      var arr = _ember['default'].A(['publication_journal-article', 'publication_review-article', 'publication_editorial-letter', 'publication_book-review', 'publication_magazine-article', 'publication_newspaper-article', 'conference_paper', 'conference_poster', 'conference_other', 'publication_journal-issue', 'publication_encyclopedia-entry', 'other']);
      return arr.indexOf(code) !== -1;
    }),

    isThesis: _ember['default'].computed('publication.publication_type', function () {
      var code = this.getCodeForPublicationType(this.get('publication.publication_type_id'));
      var arr = _ember['default'].A(['publication_doctoral-thesis', 'publication_licentiate-thesis']);
      return arr.indexOf(code) !== -1;
    }),

    isBook: _ember['default'].computed('publication.publication_type', function () {
      var code = this.getCodeForPublicationType(this.get('publication.publication_type_id'));
      var arr = _ember['default'].A(['publication_book', 'publication_textbook', 'publication_textcritical-edition', 'publication_report', 'publication_edited-book', 'conference_proceeding', 'publication_working-paper']);
      return arr.indexOf(code) !== -1;
    }),

    isChapter: _ember['default'].computed('publication.publication_type', function () {
      var code = this.getCodeForPublicationType(this.get('publication.publication_type_id'));
      var arr = _ember['default'].A(['publication_book-chapter', 'publication_report-chapter']);
      return arr.indexOf(code) !== -1;
    }),

    isRef: _ember['default'].computed('publication.ref_value', function () {
      return this.get('publication.ref_value') === 'ISREF';
    }),

    isArtisticBasis: _ember['default'].computed('publication.artistic_basis', function () {
      return this.get('publication.artistic_basis');
    }),

    allFieldObjects: _ember['default'].computed('publicationType.all_fields', function () {
      var o = _ember['default'].Object.create();
      if (this.get('publicationType.all_fields')) {
        this.get('publicationType.all_fields').forEach(function (field) {
          _ember['default'].set(o, field.name, field);
        });
      }
      return o;
    }),
    comparableVersions: _ember['default'].computed('publication.versions', function () {
      var versions = this.get('publication.versions').slice(1);
      return versions.map(function (item, i) {
        return { index: versions.length - i, item: item };
      });
    }),
    fetchVersionString: 'fetchVersion',

    refreshModelAction: 'refreshModel',

    setMessageAction: 'setMsgHeader',

    actions: {
      setMessage: function setMessage() {
        this.sendAction('refreshModelAction', this.get('publication.id'));
        this.sendAction('setMessageAction', 'success', 'Filen har tagits bort'); //@FIXME: translation
      },
      refreshModel: function refreshModel() {
        this.sendAction('refreshModelAction', this.get('publication.id'));
      },
      fetchVersion: function fetchVersion(version_id) {
        this.sendAction('fetchVersionString', this.get('publication.id'), version_id);
      }
    }
  });
});