define('frontend/controllers/admin/people/person/edit/list', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        page: 1,
        queryParams: ['page', 'sort_by', 'publication_id', 'person_id', 'department_id', 'faculty_id', 'serie_id', 'project_id', 'publication_type', 'ref_value', 'start_year', 'end_year'],
        sortSelectValues: _ember['default'].A([]),
        sort_by: 'pubyear',
        publication_id: null,
        person_id: null,
        department_id: null,
        faculty_id: null,
        serie_id: null,
        project_id: null,
        publication_type: null,
        ref_value: null,
        start_year: null,
        end_year: null
    });
});