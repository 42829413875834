define('frontend/components/gup-file-upload-widget', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    i18n: _ember['default'].inject.service(),
    errors: _ember['default'].A([]),
    fileLabel: null,
    isShowingUploadModal: false,
    faIconClass: 'fa-paperclip',
    parentUploadFile: null,
    cancelButtonIsVisible: true,
    cancelButtonStyle: 'default',
    submitButtonIsVisible: true,
    submitButtonIsDisabled: false,
    fileUploadIsVisible: true,
    fileUploadProgress: null, // does it matter if it has null or 0?
    init: function init() {
      this._super.apply(this, arguments);
      if (_ember['default'].isBlank(this.get('openModalLabel'))) {
        this.set('openModalLabel', this.get('i18n').t('components.fileUploadWidget.openModalLabel'));
      }
      if (_ember['default'].isBlank(this.get('modalTitle'))) {
        this.set('modalTitle', this.get('i18n').t('components.fileUploadWidget.modalTitle'));
      }
      if (_ember['default'].isBlank(this.get('chooseFileLabel'))) {
        this.set('chooseFileLabel', this.get('i18n').t('components.fileUploadWidget.chooseFileLabel'));
      }
      if (_ember['default'].isBlank(this.get('cancelLabel'))) {
        this.set('cancelLabel', this.get('i18n').t('components.fileUploadWidget.cancelLabel'));
      }
      if (_ember['default'].isBlank(this.get('submitLabel'))) {
        this.set('submitLabel', this.get('i18n').t('components.fileUploadWidget.submitLabel'));
      }
    },
    didInsertElement: function didInsertElement() {
      //Force two way binding
      if (this.attrs.parentUploadFile !== undefined) {
        this.attrs.parentUploadFile.update(this.get('parentUploadFile'));
      }
    },
    hasError: _ember['default'].computed('errors', function () {
      return _ember['default'].isPresent(this.get('errors'));
    }),
    chooseFileLabelOrFileLabel: _ember['default'].computed('chooseFileLabel', 'fileLabel', function () {
      return _ember['default'].isPresent(this.get('fileLabel')) ? this.get('fileLabel') : this.get('chooseFileLabel');
    }),
    actions: {
      fileDidChange: function fileDidChange(file) {
        function humanFileSize(bytes, si) {
          var thresh = si ? 1000 : 1024;
          if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
          }
          var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
          var u = -1;
          do {
            bytes /= thresh;
            ++u;
          } while (Math.abs(bytes) >= thresh && u < units.length - 1);
          return bytes.toFixed(1) + ' ' + units[u];
        }
        this.set('fileLabel', _ember['default'].isPresent(file) ? file.name + ' (' + humanFileSize(file.size) + ')' : null);
        this.sendAction('fileDidChange', file);
      },
      showUploadModal: function showUploadModal() {
        this.set('isShowingUploadModal', true);
      },
      hideUploadModal: function hideUploadModal() {
        this.set('isShowingUploadModal', false);
      },
      didSave: function didSave() {
        var _this = this;

        var success = function success() {
          _this.send('resetState');
        };
        var error = function error(reason) {
          if (reason) {
            _this.get('errors').pushObject(reason);
          }
        };
        this.sendAction('didSave', success, error);
      },
      didCancel: function didCancel() {
        this.send('resetState');
        this.sendAction('didCancel');
      },
      resetState: function resetState() {
        this.set('fileUploadProgress', null);
        this.set('fileLabel', null);
        this.get('errors').clear();
      }
    }
  });
});