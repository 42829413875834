define('frontend/controllers/publications/dashboard/manage/search', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        manageController: _ember['default'].inject.controller("publications.dashboard.manage"),
        page: 1,
        search_term: '',
        term: '',
        queryParams: ['page', 'search_term'],

        updateTempTerm: _ember['default'].observer('search_term', function () {
            this.set("term", this.get("search_term"));
        }),

        displayNoResult: _ember['default'].computed('search_term', function () {
            if (this.get("search_term").length > 0) {
                return true;
            }
            return false;
        }),

        isSearchInputEmpty: _ember['default'].computed('term', function () {
            if (this.get("term").length > 0) {
                return "is-filled";
            }
            return "is-empty";
        }),

        resetData: function resetData() {
            this.set("search_term", '');
            this.set("page", 1);
        },

        actions: {
            findPublications: function findPublications() {
                this.set("search_term", this.get("term"));
                this.set("page", 1);
            },

            resetForm: function resetForm() {
                this.set("term", '');
                this.set("search_term", this.get("term"));
            }
        }
    });
});