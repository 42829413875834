define('frontend/components/confirmation-modal-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    error: null,
    isShowingModal: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      showModal: function showModal() {
        this.set('isShowingModal', true);
      },
      hideModal: function hideModal() {
        this.set('isShowingModal', false);
      }
    }
  });
});