define('frontend/routes/admin/departments/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return {};
    },

    actions: {
      saveDepartment: function saveDepartment(model) {
        var _this = this;

        var controller = this.controller;
        var successHandler = function successHandler() {
          _this.transitionTo('admin.departments.index');
        };
        var errorHandler = function errorHandler(reason) {
          _this.send('setMsgHeader', 'error', reason.error.msg);
          controller.set('errors', reason.error.errors);
          _ember['default'].run.later(function () {
            _ember['default'].$('[data-toggle="popover"]').popover({
              placement: 'top',
              html: true
            });
          });
        };
        var generalHandler = function generalHandler(model) {
          console.log(model);

          if (model.error) {
            errorHandler(model);
          } else {
            successHandler(model);
          }
        };
        this.store.save('department', model).then(generalHandler);
      }
    }
  });
});