define('frontend/components/collapsible-items', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    items: _ember['default'].A([]),
    activeItems: _ember['default'].A([]),
    accordion: false,
    isActiveItem: function isActiveItem(item) {
      return this.get('activeItems').indexOf(item) !== -1;
    },
    itemStates: _ember['default'].computed('items.[]', 'activeItems.[]', function () {
      var _this = this;

      return this.get('items').map(function (item) {
        return _ember['default'].Object.create({ item: item, isActive: _this.isActiveItem(item) });
      });
    }),
    actions: {
      toggleActiveItem: function toggleActiveItem(item) {
        var _this2 = this;

        if (this.get('accordion')) {
          this.get('activeItems').without(item).forEach(function (item) {
            _this2.get('activeItems').removeObject(item);
          });
        }
        if (this.isActiveItem(item)) {
          this.get('activeItems').removeObject(item);
        } else {
          this.get('activeItems').pushObject(item);
        }
      }
    }
  });
});