define("frontend/templates/components/author-block", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 6
              },
              "end": {
                "line": 9,
                "column": 6
              }
            },
            "moduleName": "frontend/templates/components/author-block.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-info-circle");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["content", "item", ["loc", [null, [8, 46], [8, 54]]], 0, 0, 0, 0]],
          locals: ["item"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 11,
              "column": 2
            }
          },
          "moduleName": "frontend/templates/components/author-block.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "list-unstyled has-error");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "errors", ["loc", [null, [7, 14], [7, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 6], [9, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "frontend/templates/components/author-block.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "help-block");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'id');
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "id", ["get", "GUID", ["loc", [null, [14, 15], [14, 19]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "linkify", [["subexpr", "t", [["get", "fieldHelptext", ["loc", [null, [14, 54], [14, 67]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 51], [14, 68]]], 0, 0]], [], ["loc", [null, [14, 41], [14, 70]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "frontend/templates/components/author-block.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "alert alert-danger");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["components.authorBlock.invalidSelectedInstitutionsWarning"], ["count", ["subexpr", "@mut", [["get", "invalidSelectedDepartmentsRowsCount", ["loc", [null, [17, 104], [17, 139]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 34], [17, 141]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 35,
              "column": 2
            }
          },
          "moduleName": "frontend/templates/components/author-block.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "author-row", [], ["item", ["subexpr", "@mut", [["get", "item", ["loc", [null, [21, 13], [21, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [22, 14], [22, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "totalNumberOfItems", ["subexpr", "@mut", [["get", "totalNumberOfItems", ["loc", [null, [23, 27], [23, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "setMsgHeader", ["subexpr", "@mut", [["get", "setMsgHeader", ["loc", [null, [24, 21], [24, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "institutions", ["subexpr", "@mut", [["get", "institutions", ["loc", [null, [25, 21], [25, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "removeAuthor", ["subexpr", "action", ["removeAuthorRow"], [], ["loc", [null, [26, 21], [26, 47]]], 0, 0], "isThisTheOnlyAuthorRow", ["subexpr", "@mut", [["get", "isThisTheOnlyAuthorRow", ["loc", [null, [27, 31], [27, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "moveUp", ["subexpr", "action", ["moveUp"], [], ["loc", [null, [28, 15], [28, 32]]], 0, 0], "moveDown", ["subexpr", "action", ["moveDown"], [], ["loc", [null, [29, 17], [29, 36]]], 0, 0], "queryAuthorsResult", ["subexpr", "action", ["queryAuthorsResult"], [], ["loc", [null, [30, 27], [30, 56]]], 0, 0], "submitCallbacks", ["subexpr", "@mut", [["get", "submitCallbacks", ["loc", [null, [31, 24], [31, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "onInvalidSelectedDepartmentsEmpty", ["subexpr", "action", ["invalidSelectedDepartmentsEmpty", ["get", "item", ["loc", [null, [32, 84], [32, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 42], [32, 89]]], 0, 0], "onInvalidSelectedDepartmentsPresent", ["subexpr", "action", ["invalidSelectedDepartmentsPresent", ["get", "item", ["loc", [null, [33, 88], [33, 92]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 44], [33, 93]]], 0, 0]], ["loc", [null, [20, 4], [34, 6]]], 0, 0]],
        locals: ["item", "index"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 0
          }
        },
        "moduleName": "frontend/templates/components/author-block.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" T_YES-READY ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" T_WONT-FIX ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "id", "author-component");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "control-label");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "text-danger");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-9");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "dotted-line");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-primary");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "fa fa-plus");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [4]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [9, 1, 3]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [2]), 0, 0);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        morphs[4] = dom.createMorphAt(element1, 5, 5);
        morphs[5] = dom.createMorphAt(element1, 6, 6);
        morphs[6] = dom.createMorphAt(element1, 7, 7);
        morphs[7] = dom.createElementMorph(element3);
        morphs[8] = dom.createMorphAt(element3, 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "errors", ["loc", [null, [3, 43], [3, 49]]], 0, 0, 0, 0], "has-error"], [], ["loc", [null, [3, 38], [3, 63]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "authorHeaderText", ["loc", [null, [4, 36], [4, 56]]], 0, 0, 0, 0], ["inline", "t", ["components.authorBlock.mandatory"], [], ["loc", [null, [4, 87], [4, 127]]], 0, 0], ["block", "if", [["get", "errors", ["loc", [null, [5, 8], [5, 14]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 2], [11, 9]]]], ["block", "if", [["get", "fieldHelptext", ["loc", [null, [13, 8], [13, 21]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [13, 2], [15, 9]]]], ["block", "if", [["get", "invalidSelectedDepartmentsRowsCount", ["loc", [null, [16, 8], [16, 43]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [16, 2], [18, 9]]]], ["block", "each", [["get", "authorArr", ["loc", [null, [19, 10], [19, 19]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [19, 2], [35, 11]]]], ["element", "action", ["addEmptyAuthorRow"], [], ["loc", [null, [39, 38], [39, 68]]], 0, 0], ["inline", "t", ["components.authorBlock.btnAddNewAuthor"], [], ["loc", [null, [39, 69], [39, 115]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});