define('frontend/routes/admin/people/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('admin.people.title');
    },
    queryParams: {
      qp: { refreshModel: true }
    },
    model: function model(params) {
      return this.store.find("person_record", { search_term: params.qp, ignore_affiliation: true });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      sessionStorage.setItem('admin.people.lastQuery', controller.get('qp'));
      if (sessionStorage.getItem('admin.people.changeWarning') === 'true') {
        controller.set('personChangeWarningAfterEditActive', true);
        sessionStorage.removeItem('admin.people.changeWarning');
      } else {
        controller.set('personChangeWarningAfterEditActive', false);
      }

      // Fill search field with query from url so that it is not lost on reload
      controller.set('query', controller.get('qp'));
    },
    actions: {
      deletePerson: function deletePerson(model) {
        var _this = this;

        if (confirm(this.get('i18n').t('admin.people.index.confirmDeletePerson'))) {
          this.store.destroy('person', model.id).then(function () {
            _this.refresh(model.id);
          });
        }
      }
    }
  });
});