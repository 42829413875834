define('frontend/components/category-selector', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isEditing: false,
    error: null,

    initComponent: _ember['default'].on('init', function () {
      this.set('filterString', '');
    }),

    updateCategories: _ember['default'].observer('filterString', function () {
      var _this = this;

      this.store.find('category', { query: this.get('filterString') }).then(function (response) {
        _this.set('categories', response);
      }, function (error) {
        _this.set('error', error.error.msg);
      });
    }),

    actions: {
      removeSelectedCategory: function removeSelectedCategory(id) {
        this.get('categoryList').removeObject(id);
      },

      addSelectedCategory: function addSelectedCategory(id) {
        // Check if category already exists in list
        if (this.get('categoryList').indexOf(id) === -1) {
          this.get('categoryList').pushObject(id);
        }
      },

      clearSearch: function clearSearch() {
        this.set('filterString', '');
      }
    }
  });
});