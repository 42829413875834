define('frontend/routes/publications/dashboard/manage/new', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('publications.dashboard.manage.new.title');
    },
    model: function model() {
      return _ember['default'].RSVP.hash({ dataSources: this.store.find("data_source") });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('selectedSource', null);
      controller.set('sourceId', null);
      controller.set('importData', null);
      controller.set('error', null);

      var dataSources = model.dataSources.map(function (source) {
        return { value: source.code, label: source.label };
      });
      controller.set('dataSources', dataSources);
      controller.set("manageController.isNavVisible", false);
    }

  });
});