define('frontend/controllers/publications/dashboard/manage/published', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    manageController: _ember['default'].inject.controller("publications.dashboard.manage"),
    page: 1,
    queryParams: ['page', 'sort_by'],
    sortSelectValues: _ember['default'].A([]),
    sort_by: 'pubyear'

  });
});