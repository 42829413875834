define('frontend/controllers/publications/dashboard/manage/show/index', ['exports', 'ember', 'frontend/config/environment'], function (exports, _ember, _frontendConfigEnvironment) {
  //TODO: Sane way of getting path

  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    applicationController: _ember['default'].inject.controller('application'),
    publicationsController: _ember['default'].inject.controller('publications'),
    publicationsShowController: _ember['default'].inject.controller('publications/dashboard/manage/show'),
    isExtendedViewMode: false,
    queryParams: ['other_version'],
    other_version: null,
    //AssetData
    assetDataErrors: _ember['default'].A([]),
    assetDataBaseUrl: _frontendConfigEnvironment['default'].APP.fileURL,
    assetDataLicenceUrl: _frontendConfigEnvironment['default'].APP.licenceURL,
    assetDataLicenceCode: _frontendConfigEnvironment['default'].APP.licenceCode,
    assetDataIsAccepted: false,
    assetDataIsEmbargo: false,
    assetDataEmbargoDate: Date(),
    assetDataUploadFile: null,
    assetDataSubmitButtonIsDisabled: true,
    assetData: null,
    isShowingAssetDataUploadModal: false,

    error: _ember['default'].computed('model.error', function () {
      if (this.get('model.error')) {
        return true;
      }
      return false;
    }),

    deletePublication: function deletePublication(id) {
      var that = this;
      this.store.destroy('publication', id).then(function () {
        that.send('setMsgHeader', 'success', that.get('i18n').t('publications.dashboard.manage.show.index.deletePublicationSuccess'));
        var target = that.get('applicationController.currentList') || 'publications.dashboard.manage.drafts';
        that.transitionToRoute(target);
      }, function () {
        that.send('setMsgHeader', 'error', that.get('i18n').t('publications.dashboard.manage.show.index.deletePublicationError'));
      });
    },
    approvePublication: function approvePublication(id) {
      var that = this;
      this.store.save('biblreview_publication', { id: id }).then(function () {
        that.send('setMsgHeader', 'success', that.get('i18n').t('publications.dashboard.manage.show.index.approvePublicationSuccess'));
        that.transitionToRoute('publications.dashboard.biblreview');
      }, function () {
        that.send('setMsgHeader', 'error', that.get('i18n').t('publications.dashboard.manage.show.index.approvePublicationError'));
      });
    },

    //advancedMode: Ember.computed('session.content.can_biblreview', 'viewMode', function() {
    //  var is_reviewer = this.get('session.content.can_biblreview');
    //  var view_mode = this.get('viewMode');
    //  return (is_reviewer && (view_mode === 'advanced'));
    //}),

    getAllPublicationTypes: _ember['default'].computed('model.publication_type', function () {
      return this.get("publicationsController.publicationTypes");
    }),

    getPublicationTypeObject: _ember['default'].computed('model.publication_type', function () {
      return this.get("publicationsController.publicationTypes").findBy("id", this.get("model.publication_type_id"));
    }),

    fileUrl: _ember['default'].computed('assetDataBaseUrl', 'assetData', function () {
      var assetData = this.get('assetData');
      if (assetData) {
        var token = this.get('session.data.authenticated.token');
        return this.get('assetDataBaseUrl') + '/' + assetData.id + '?tmp_token=' + assetData.tmp_token + '&token=' + token;
      } else {
        return null;
      }
    }),

    actions: {
      assetDataFileDidChange: function assetDataFileDidChange(file) {
        var _this = this;

        if (_ember['default'].isPresent(file)) {
          this.get('assetDataUploadFile')(file).then(function (response) {
            if ('asset_data' in response) {
              _this.get('assetDataErrors').clear();
              _this.set('assetData', response.asset_data);
              _this.set('assetDataSubmitButtonIsDisabled', false);
            }
          }, function (reason) {
            _this.get('assetDataErrors').pushObject(reason);
            _this.set('assetDataSubmitButtonIsDisabled', true);
            _this.set('assetData', null);
          });
        }
      },
      didSaveAssetData: function didSaveAssetData(success, error) {
        var _this2 = this;

        if (this.get('assetDataIsEmbargo')) {
          this.set('assetData.visible_after', this.get('assetDataEmbargoDate'));
        } else {
          this.set('assetData.visible_after', null);
        }
        if (this.get('assetDataIsAccepted')) {
          this.set('assetData.accepted', this.get('assetDataLicenceCode'));
        } else {
          this.set('assetData.accepted', null);
        }
        this.store.save('asset_data', this.get('assetData')).then(function (model) {
          //Is there a case when 200 will produce error in asset_data controller, don't think so
          //TODO: can remove this?
          if (model.error) {
            error(model.error.msg);
          } else {
            _this2.send('setMsgHeader', 'success', _this2.get('i18n').t('publications.dashboard.manage.show.index.saveAssetDataSuccess'));
            _this2.send('resetAssetDataState');
            //this.send('refreshModel', this.get('publication.id'));
            //TODO: how does this work?
            _this2.set('isShowingAssetDataUploadModal', false);
            _this2.send('refreshModel');
            success();
          }
        }, function (errorResponse) {
          if (errorResponse.error) {
            error(errorResponse.error.msg);
          } else {
            //TODO: Handle backend crash error!
          }
        });
      },
      didCancelAssetData: function didCancelAssetData() {
        //TODO: What if leaves window without closing modal?
        this.send('resetAssetDataState');
      },
      resetAssetDataState: function resetAssetDataState() {
        this.set('assetDataErrors', _ember['default'].A([]));
        this.set('assetDataIsAccepted', false);
        this.set('assetDataIsEmbargo', false);
        this.set('assetDataEmbargoDate', Date());
        this.set('assetData', null);
      },
      goBack: function goBack() {
        var target = this.get('applicationController.currentList');
        if (!target) {
          window.history.back();
          return;
        }
        this.transitionToRoute(target);
      },
      deletePublication: function deletePublication(id) {
        //Use modal component?
        var r = confirm(this.get('i18n').t('publications.dashboard.manage.show.index.confirmDeletePublication'));
        if (r === true) {
          this.deletePublication(id);
        }
      },
      approvePublication: function approvePublication(id) {
        this.approvePublication(id);
      },
      toggleViewMode: function toggleViewMode() {
        this.toggleProperty('isExtendedViewMode');
      },
      toggleCompareMode: function toggleCompareMode() {
        this.toggleProperty('isExtendedCompareMode');
      }
    }
  });
});