define('frontend/components/publication-field-files', ['exports', 'ember', 'frontend/config/environment'], function (exports, _ember, _frontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    fileBaseUrl: _frontendConfigEnvironment['default'].APP.fileURL,
    refreshModelAction: 'refreshModel',
    setMsgHeaderAction: 'setMessage',

    init: function init() {
      this._super.apply(this, arguments);
      // TODO: this is never used? Needed?
      this.set('token', this.get('session.data.authenticated.token'));
    },

    published: _ember['default'].computed('processState', function () {
      if (this.get('processState') === 'DRAFT' || this.get('processState') === 'PREDRAFT') {
        return false;
      }
      return true;
    }),

    actions: {
      removeFile: function removeFile(id) {
        var _this = this;

        var result = window.confirm('Är du säker på att du vill ta bort filen?'); // @FIXME Translation
        if (!result) {
          return;
        } else {
          // Delete file
          this.store.destroy('asset_data', id).then(function () {
            _this.sendAction('refreshModelAction', _this.get('publication.id'));
            _this.sendAction('setMsgHeaderAction', 'success', 'Filen har tagits bort'); // @FIXME Translation
          });
        }
      }
    }
  });
});