define('frontend/controllers/publications/dashboard/manage/drafts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    manageController: _ember['default'].inject.controller("publications.dashboard.manage"),
    page: 1,
    queryParams: ['page'],

    i18n: _ember['default'].inject.service(),
    deletePublication: function deletePublication(id) {
      var that = this;
      this.store.destroy('draft', id).then(function () {
        that.send('setMsgHeader', 'success', that.get('i18n').t('publications.dashboard.manage.drafts.deleteDraftSuccess'));
        that.send('refreshModel');
      }, function () {
        that.send('setMsgHeader', 'success', that.get('i18n').t('publications.dashboard.manage.drafts.deleteDraftError'));
      });
    },
    actions: {
      'delete': function _delete(id) {
        var r = confirm(this.get('i18n').t('publications.dashboard.manage.drafts.confirmDeleteDraft'));
        if (r === true) {
          this.deletePublication(id);
        }
      }
    }
  });
});