define('frontend/routes/admin/messages', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('admin.messages.title');
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        news_message: this.store.find('message', 'NEWS'),
        alert_message: this.store.find('message', 'ALERT')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var news_message = {};
      var alert_message = {};
      if (model.news_message.message) {
        news_message = model.news_message;
      } else {
        news_message = { message_type: 'NEWS', message: '', start_date: null, end_date: null };
      }

      if (model.alert_message.message) {
        alert_message = model.alert_message;
      } else {
        alert_message = { message_type: 'ALERT', message: '', start_date: null, end_date: null };
      }

      controller.set('news_message', news_message);
      controller.set('alert_message', alert_message);
    },
    actions: {
      saveMessage: function saveMessage(model) {
        var _this = this;

        model.id = null;
        this.store.save('message', model).then(function () {
          _this.send('setMsgHeader', 'success', _this.get('i18n').t('admin.messages.saved'));
        }, function () {
          _this.send('setMsgHeader', 'error', _this.get('i18n').t('admin.messages.saveError'));
        });
      },
      deleteMessage: function deleteMessage(model) {
        var _this2 = this;

        model.id = null;
        this.store.destroy('message', model.message_type).then(function () {
          _this2.send('setMsgHeader', 'success', _this2.get('i18n').t('admin.messages.deleted'));
          _this2.refresh();
        }, function () {
          _this2.send('setMsgHeader', 'error', _this2.get('i18n').t('admin.messages.deleteError'));
        });
      }
    }
  });
});