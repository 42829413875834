define('frontend/controllers/publications_list', ['exports', 'ember', 'frontend/config/environment', 'frontend/lib/validations'], function (exports, _ember, _frontendConfigEnvironment, _frontendLibValidations) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    page: 1,
    queryParams: ['page', 'sort_by', 'publication_id', 'person_id', 'department_id', 'faculty_id', 'serie_id', 'project_id', 'publication_type', 'ref_value', 'start_year', 'end_year', 'only_artistic'],
    sortSelectValues: _ember['default'].A([]),
    sort_by: 'pubyear',
    publication_id: null,
    person_id: "",
    department_id: null,
    faculty_id: null,
    serie_id: null,
    project_id: null,
    publication_type: null,
    ref_value: null,
    start_year: null,
    end_year: null,
    only_artistic: false,

    selectedFacultyID: null,
    selectedSeries: [],
    selectedProjects: [],
    selectedDepartments: [],
    selectedAuthors: [],
    selectedPublicationTypes: [],
    isRef: false, // translate to ISREF/NOTREF in queryparam ref_value
    isArtistic: false,
    base_start_year: 1942,
    base_end_year: null, // is set in setupController

    resetPaging: _ember['default'].observer("selectedPublicationTypes", "selectedProjects", "selectedSeries", "selectedFacultyID", "selectedAuthors", "selectedDepartments", "isRef", "start_year", "end_year", "isArtistic", function () {
      this.set("page", 1);
    }),

    resultIsVisible: _ember['default'].computed("selectedPublicationTypes", "selectedProjects", "selectedSeries", "selectedFacultyID", "selectedAuthors", "selectedDepartments", "isRef", "start_year", "end_year", "isArtistic", function () {
      return true; // simple fix for now. We need to always display results from start for indexing in google scholar
      //if (this.get("person_id") || this.get("department_id") || this.get("ref_value") || this.get("start_year") || this.get("end_year")) {
      //  return true;
      //}
      //return false;
    }),

    getDownloadLink: _ember['default'].computed("selectedPublicationTypes", "selectedProjects", "selectedSeries", "selectedFacultyID", "selectedAuthors", "selectedDepartments", "isRef", "start_year", "end_year", "isArtistic", function () {
      return _frontendConfigEnvironment['default'].APP.serviceURL + "/public_publication_lists" + "?sortby=" + this.get("sort_by") + "&publication_id=" + (this.get("publication_id") ? this.get("publication_id") : '') + "&person_id=" + (this.get("person_id") ? this.get("person_id") : '') + "&department_id=" + (this.get("department_id") ? this.get("department_id") : '') + "&faculty_id=" + (this.get("faculty_id") ? this.get("faculty_id") : '') + "&serie_id=" + (this.get("serie_id") ? this.get("serie_id") : '') + "&project_id=" + (this.get("project_id") ? this.get("project_id") : '') + "&publication_type=" + (this.get("publication_type") ? this.get("publication_type") : '') + "&ref_value=" + (this.get("ref_value") ? this.get("ref_value") : '') + "&start_year=" + (this.get("start_year") ? this.get("start_year") : '') + "&end_year=" + (this.get("end_year") ? this.get("end_year") : '') + "&only_artistic=" + (this.get("only_artistic") ? this.get("only_artistic") : '') + "&output=ris";
    }),

    getMaxNumberOfDownloads: _ember['default'].computed('model', function () {
      return _frontendConfigEnvironment['default'].APP.GUP_MAX_NUMBER_OF_POSTS_RIS;
    }),

    disableDownloadAsEndnote: _ember['default'].computed("model.meta.query.total", function () {
      return this.get("model.meta.query.total") > parseInt(_frontendConfigEnvironment['default'].APP.GUP_MAX_NUMBER_OF_POSTS_RIS);
    }),

    getLink: _ember['default'].computed("session", function () {
      if (this.get("session.isAuthenticated")) {
        return "publications.dashboard.manage.show";
      }
      return "publication";
    }),

    selectedFacultyChanged: _ember['default'].observer('selectedFacultyID', function () {
      this.set('faculty_id', this.get('selectedFacultyID'));
    }),

    selectedSeriesChanged: _ember['default'].observer('selectedSeries', function () {
      this.formatForQueryStr('serie_id', this.get('selectedSeries'));
    }),

    selectedProjectsChanged: _ember['default'].observer('selectedProjects', function () {
      this.formatForQueryStr('project_id', this.get('selectedProjects'));
    }),

    selectedPublicationTypesChanged: _ember['default'].observer('selectedPublicationTypes', function () {
      this.formatForQueryStr('publication_type', this.get('selectedPublicationTypes'));
    }),

    selectedDepartmentsChanged: _ember['default'].observer('selectedDepartments', function () {
      this.formatForQueryStr('department_id', this.get('selectedDepartments'));
    }),

    selectedAuthorsChanged: _ember['default'].observer('selectedAuthors', function () {
      this.formatForQueryStr('person_id', this.get('selectedAuthors'));
    }),

    isRefValueChanged: _ember['default'].observer('ref_value', function () {
      if (this.get("ref_value") !== 'ISREF') {
        this.set("isRef", false);
      } else {
        this.set("isRef", true);
      }
    }),

    isArtisticValueChanged: _ember['default'].observer('isArtistic', function () {
      this.set('only_artistic', this.get('isArtistic'));
    }),

    yearRangeDepartments: _ember['default'].computed('start_year', 'end_year', function () {
      var startYear = this.get('start_year') || '';
      var endYear = this.get('end_year') || '';
      var validStartYear = (0, _frontendLibValidations.validYear)(startYear);
      var validEndYear = (0, _frontendLibValidations.validYear)(endYear);
      var departments = this.get('departments');

      // TODO: Validations not needed
      if (validStartYear) {
        startYear = parseInt(startYear);
        departments = departments.filter(function (item) {
          return !_ember['default'].isPresent(item.end_year) || item.end_year >= startYear;
        });
      }
      if (validEndYear) {
        endYear = parseInt(endYear);
        departments = departments.filter(function (item) {
          return !_ember['default'].isPresent(item.start_year) || item.start_year <= endYear;
        });
      }
      return departments;
    }),

    selectableFaculties: _ember['default'].computed('yearRangeDepartments', function () {
      // @TODO: this could be computed prop for increased performance
      var facultyIds = this.get('yearRangeDepartments').reduce(function (result, department) {
        result[department.faculty_id] = department.faculty_id;
        return result;
      }, []);
      var faculties = this.get('faculties');
      return facultyIds.map(function (id) {
        //TODO: this could be made much faster by indexing faculties by id instead
        return faculties.findBy('id', id) || _ember['default'].Object.create({
          id: id,
          name: 'Unknown/Extern (id: ' + id + ')'
        });
      });
    }),

    selectableDepartments: _ember['default'].computed('yearRangeDepartments', 'selectedFacultyID', function () {
      var _this = this;

      var departments = this.get('yearRangeDepartments');

      // TODO: Should document: why do we do this?
      departments.forEach(function (item) {
        item.children = null;
      });

      // remove here
      if (_ember['default'].isPresent(this.get('selectedFacultyID'))) {
        (function () {
          var facultyId = _this.get('selectedFacultyID');
          departments = departments.filter(function (item) {
            return facultyId === item.faculty_id;
          });
        })();
      }

      if (_ember['default'].isPresent(this.get('selectedDepartments'))) {
        var selectable_selected_departments = this.get('selectedDepartments').filter(function (department) {
          return departments.findBy('id', department.id);
        });
        //If one or more selected department no longer among selectable, set new valid filtered selection
        if (this.get('selectedDepartments').length !== selectable_selected_departments.length) {
          this.set('selectedDepartments', selectable_selected_departments);
        }
      }

      return departments;
    }),

    isRefChanged: _ember['default'].observer('isRef', function () {
      if (this.get("isRef")) {
        this.set("ref_value", "ISREF");
      } else {
        this.set("ref_value", null);
      }
    }),

    rangeYear: _ember['default'].computed('base_start_year', 'base_end_year', function () {
      var arr = [];
      for (var i = this.get("base_start_year"); i < this.get("base_end_year"); i++) {
        arr[i] = i.toString();
      }
      return arr;
    }),

    formatForQueryStr: function formatForQueryStr(name, selectedValues) {
      // takes array and formats to semicolon separated string
      this.set(name, selectedValues.mapBy("id").join(";"));
    },

    actions: {
      searchAuthor: function searchAuthor(term) {
        return this.store.find('person_record', { search_term: term });
      },
      searchDepartment: function searchDepartment(term) {
        return this.store.find('department', { search_term: term });
      }
    }
  });
});