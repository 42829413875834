define('frontend/components/publication-field-compare', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'tr',

    classNameBindings: ['isDifferent:compare-row-different'],
    isCollapsed: true,

    isDifferent: _ember['default'].computed('fieldValue', 'otherFieldValue', 'type', 'otherIsSelected', 'listValueArray', 'listOtherValueArray', function () {
      var leftValue = this.get('fieldValue');
      var rightValue = this.get('otherFieldValue');
      var type = this.get('type');

      /* Ignore checks when other version is not yet selected */
      if (!this.get('otherIsSelected')) {
        return false;
      }

      /* Convert lists and authors into strings before comparison */
      if (type === 'authors') {
        leftValue = JSON.stringify(leftValue);
        rightValue = JSON.stringify(rightValue);
      }

      if (type === 'links') {
        leftValue = JSON.stringify(leftValue);
        rightValue = JSON.stringify(rightValue);
      }

      if (type === 'list') {
        leftValue = JSON.stringify(this.get('listValueArray'));
        rightValue = JSON.stringify(this.get('listOtherValueArray'));
      }

      if (leftValue !== rightValue) {
        return true;
      }

      return false;
    }),

    isTypeLinks: _ember['default'].computed.equal('type', 'links'),

    isTypeList: _ember['default'].computed.equal('type', 'list'),

    isTypeAuthors: _ember['default'].computed.equal('type', 'authors'),

    isTypeCollapsible: _ember['default'].computed.equal('type', 'collapsed'),

    listValueArray: _ember['default'].computed('fieldValue', 'listLabel', 'listValue', function () {

      var listValue = this.get('listValue');
      var listLabel = this.get('listLabel');

      return this.get('fieldValue').map(function (listItem) {
        if (listLabel) {
          return listItem[listLabel] + ': ' + listItem[listValue];
        } else {
          return listItem[listValue];
        }
      });
    }),

    listOtherValueArray: _ember['default'].computed('otherFieldValue', 'listLabel', 'listValue', function () {

      var listValue = this.get('listValue');
      var listLabel = this.get('listLabel');

      if (!this.get('otherFieldValue')) {
        return [];
      }

      return this.get('otherFieldValue').map(function (listItem) {
        if (listLabel) {
          return listItem[listLabel] + ': ' + listItem[listValue];
        } else {
          return listItem[listValue];
        }
      });
    }),

    collapsedValue: _ember['default'].computed('fieldValue', function () {
      //var collapsedLength = 150;
      var thresholdLength = 200;
      var fieldValue = this.get('fieldValue');
      if (fieldValue && fieldValue.length > thresholdLength) {
        return fieldValue.substring(0, thresholdLength) + '...';
      } else {
        return null;
      }
    }),

    collapsedOtherValue: _ember['default'].computed('otherFieldValue', function () {
      //var collapsedLength = 150;
      var thresholdLength = 200;
      var fieldValue = this.get('otherFieldValue');
      if (fieldValue && fieldValue.length > thresholdLength) {
        return fieldValue.substring(0, thresholdLength) + '...';
      } else {
        return null;
      }
    }),

    actions: {
      toggleCollapsed: function toggleCollapsed() {
        this.toggleProperty('isCollapsed');
      }
    }

  });
});