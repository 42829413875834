define('frontend/controllers/publications/dashboard', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),
		application: _ember['default'].inject.controller(),
		showReviewCount: _ember['default'].computed.gt('application.userdata.counts.review', 0),

		fetchUserdata: function fetchUserdata() {
			var that = this;
			this.store.find('userdata', this.get('session.data.authenticated.username')).then(function (data) {
				that.set("application.userdata", data);
			});
		},
		fetchUserdataLoop: function fetchUserdataLoop(interval) {
			if (this.get("application.userdata_active")) {
				return false;
			}

			this.set("application.userdata_active", true);
			if (!interval) {
				interval = 60000;
			}
			this.fetchUserdata();
			_ember['default'].run.later(this, function () {
				this.fetchUserdataLoop();
			}, interval);
		}

	});
});