define('frontend/components/confirmation-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    isShowing: false,
    error: null,
    modalTitle: null,
    cancelText: null,
    confirmText: null,
    usesTransition: true,
    init: function init() {
      this._super.apply(this, arguments);
      if (_ember['default'].isBlank(this.get('modalTitle'))) {
        this.set('modalTitle', this.get('i18n').t('components.confirmationModal.modalTitle'));
      }
      if (_ember['default'].isBlank(this.get('cancelText'))) {
        this.set('cancelText', this.get('i18n').t('components.confirmationModal.cancelText'));
      }
      if (_ember['default'].isBlank(this.get('confirmText'))) {
        this.set('confirmText', this.get('i18n').t('components.confirmationModal.confirmText'));
      }
    },
    actions: {
      didConfirm: function didConfirm() {
        var _this = this;

        // @TODO: check if promise or ordinary function?
        var didConfirm = this.get('didConfirm');
        var closeAndReset = function closeAndReset() {
          _this.set('error', null);
          _this.set('isShowing', false);
        };
        if (_ember['default'].isPresent(didConfirm)) {
          // If is promise
          if (typeof didConfirm === 'function') {
            didConfirm();
            closeAndReset();
          } else if (typeof didConfirm === 'object' && typeof didConfirm.then === 'function') {
            // Disable controls while resolving promise?
            this.didConfirm().then(function () {
              closeAndReset();
            }, function (error) {
              // Perhaps remove this, only handle success? Feels dodgy
              // @TODO: format of error message? error.msg?
              if (_ember['default'].typeOf(error) === 'string') {
                _this.set('error', error);
              } else {
                _this.set('error', _this.get('i18n').t('components.confirmationModal.unknownError'));
              }
            });
          } else {
            throw "Invalid type for property 'didConfirm'";
          }
        } else {
          closeAndReset();
        }
      }
    }
  });
});