define('frontend/routes/publications', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model() {
      return _ember['default'].RSVP.hash({
        publicationTypes: this.store.find('publication_type'),
        faculties: this.store.find('faculty')
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('publicationTypes', model.publicationTypes);
      controller.set('faculties', model.faculties);
    }

  });
});