define('frontend/components/select2-adjusted', ['exports', 'ember', 'ember-select-2/components/select-2'], function (exports, _ember, _emberSelect2ComponentsSelect2) {
  exports['default'] = _emberSelect2ComponentsSelect2['default'].extend({
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      // GUB: Listen for select2 opening so that we can populate the search field
      // with provided data.
      // Only populate field if setDefaultQuery tells us to do so.
      this._select.on('select2-opening', _ember['default'].run.bind(this, function () {
        var _this = this;

        if (this.get('setDefaultQuery')) {
          _ember['default'].run.schedule('afterRender', function () {
            _ember['default'].$('.' + _this.get('cssClass')).find('input.select2-input').first().val(_this.get('defaultQuery'));
            _this._select.select2('search', _this.get('defaultQuery'));
          });
        }
      }));
      /*
      var self = this;
      this._select.on("select2-opening", Ember.run.bind(this, function() {
        if(self.get('setDefaultQuery')) {
          Ember.run.later(function() {
            var inputs = Ember.$('.'+self.get('cssClass')).find('input.select2-input');
            inputs.first().val(self.get('defaultQuery'));
            self._select.select2('search', self.get('defaultQuery'));
          });
        }
      }));
      */

      var selector = '.' + this.get('cssClass');
      var that = this;
      _ember['default'].run.schedule('afterRender', function () {
        _ember['default'].$(selector).find('.select2-drop').append('<div class="select2-footer"><i class="fa fa-info-circle"></i> ' + that.get('didNotFindWhatYouWereLookingForStr') + ' <a href="javascript:void()" id="toggleBtn">' + that.get('btnText') + '</a></div>');
        if (_this2.get("defaultQuery")) {
          _ember['default'].$(selector).select2('open');
        }
        _ember['default'].$(selector).find("#toggleBtn").bind('click', function () {
          _ember['default'].run(function () {
            // Close dropdown
            _this2.set('zeroResult', true);
          });
        });
      });
    }
  });
});