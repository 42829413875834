define('frontend/controllers/publication', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    allFieldObjects: _ember['default'].computed('publicationType.all_fields', function () {
      var o = _ember['default'].Object.create();
      if (this.get('publicationType.all_fields')) {
        this.get('publicationType.all_fields').forEach(function (field) {
          _ember['default'].set(o, field.name, field);
        });
      }
      return o;
    }),

    getPublicationTypeObject: _ember['default'].computed('publicationTypes', function () {
      var fullObjectPubtype = this.get('publicationTypes').findBy('code', this.get('selectedPublicationType'));
      return fullObjectPubtype;
    }),

    publicationType: _ember['default'].computed('publication.publication_type', function () {
      return this.get("publicationTypes").findBy("id", this.get("publication.publication_type_id"));
    })

  });
});