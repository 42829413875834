define('frontend/components/publication-identifier-block', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    actions: {
      deleteIdentifier: function deleteIdentifier(publicationIdentifier) {
        this.get('publicationIdentifiers').removeObject(publicationIdentifier);
      },
      createIdentifier: function createIdentifier(code, value) {
        var label = this.get('publicationIdentifierCodes').findBy('code', code).label;
        var publicationIdentifier = { identifier_code: code, identifier_value: value, identifier_label: label };
        this.get('publicationIdentifiers').addObject(publicationIdentifier);
        this.set('identifierCode', null);
        this.set('identifierValue', '');
      }
    }
  });
});