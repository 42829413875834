define('frontend/routes/publications/dashboard/manage/search', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get("i18n").t('publications.dashboard.manage.search.title');
    },
    queryParams: {
      page: { refreshModel: true },
      search_term: { refreshModel: true }
    },
    afterModel: function afterModel(transition) {
      this.controllerFor('application').set('currentList', transition.targetName);
    },
    model: function model(params) {
      return this.store.find("publication_record", params);
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.set("manageController.isNavVisible", true);
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.resetData();
      }
    }
  });
});