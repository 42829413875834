define('frontend/controllers/publications/dashboard/manage/file-imports', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    manageController: _ember['default'].inject.controller("publications.dashboard.manage"),
    importDataBaseUrl: null,
    uploadImportDataFile: null,
    importDataFile: null,
    uploadSubmitButtonIsVisible: true,
    uploadCancelButtonStyle: 'default',
    uploadCancelButtonLabel: null,
    uploadSubmitButtonIsDisabled: true,
    uploadFileUploadIsVisible: true,
    hasSuccessfullUpload: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('importDataBaseUrl', _ember['default'].getOwner(this).resolveRegistration('config:environment').APP.serviceURL + '/endnote_files');
      this.set('uploadCancelButtonLabel', this.get('i18n').t('publications.dashboard.manage.fileImports.uploadCancelLabel'));
    },
    actions: {
      didUploadImportDataFile: function didUploadImportDataFile(response) {
        if ('import_data' in response) {
          this.set('importData', response.asset_data);
        }
      },
      importDataFileDidChange: function importDataFileDidChange(file) {
        this.set('uploadSubmitButtonIsDisabled', false);
        this.set('importDataFile', file);
      },
      didSaveImportData: function didSaveImportData(success, error) {
        var _this = this;

        this.set('uploadSubmitButtonIsDisabled', true);
        this.get('uploadImportDataFile')(this.get('importDataFile')).then(function () {
          _this.set('uploadSubmitButtonIsVisible', false);
          _this.set('uploadFileUploadIsVisible', false);
          _this.set('uploadCancelButtonStyle', 'success');
          _this.set('uploadCancelButtonLabel', _this.get('i18n').t('publications.dashboard.manage.fileImports.uploadCloseLabel'));
          _this.set('hasSuccessfullUpload', true);
          _this.send('refreshModel');
          success();
        }, function (message) {
          _this.set('hasSuccessfullUpload', false);
          _this.set('uploadSubmitButtonIsDisabled', false);
          error(message);
        });
      },
      didCancelImportData: function didCancelImportData() {
        this.send('resetFileImportUploadState');
      },
      didDeleteFileImport: function didDeleteFileImport(fileImport) {
        var _this2 = this;

        return this.store.destroy('endnote_file', fileImport.get('id')).then(function () {
          _this2.send('refreshModel');
          _this2.send('setMsgHeader', 'success', _this2.get('i18n').t('publications.dashboard.manage.fileImports.deletionSuccess', { filename: fileImport.name }));
        }, function () {
          //@TODO: Display backend error reason?
          _this2.send('setMsgHeader', 'error', _this2.get('i18n').t('publications.dashboard.manage.fileImports.deletionError', { filename: fileImport.name }));
        });
      },
      resetFileImportUploadState: function resetFileImportUploadState() {
        this.set('importData', null);
        this.set('importDataFile', null);
        this.set('uploadSubmitButtonIsVisible', true);
        this.set('uploadCancelButtonLabel', this.get('i18n').t('publications.dashboard.manage.fileImports.uploadCancelLabel'));
        this.set('uploadCancelButtonStyle', 'default');
        this.set('uploadSubmitButtonIsDisabled', true);
        this.set('uploadFileUploadIsVisible', true);
        this.set('hasSuccessfullUpload', false);
      },
      importEndNoteRecord: function importEndNoteRecord(record) {
        var _this3 = this;

        var importRecordAndEdit = new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _this3.store.save('import_data', { datasource: 'endnote', sourceid: record.id }).then(function (model) {
            if (model.error) {
              //Let our last resort error handler deal with this
              reject(model.error.msg);
            } else {
              _this3.store.save('draft', model).then(function (model) {
                // This is so sweet
                // Second catch function needed? Check what happens with invalid id
                _this3.transitionToRoute('publications.dashboard.manage.show.edit', model.id, {
                  queryParams: {
                    returnTo: 'publications.dashboard.manage.file_imports',
                    returnToQueryParams: { 'isShowingRecordId': record.id }
                  }
                }).then(resolve, resolve);
              }, function (error) {
                _ember['default'].run(function () {
                  _this3.send('setMsgHeader', 'error', error.error.msg);
                  resolve();
                });
              });
            }
          }, function (error) {
            reject(error.error.msg);
          });
        })['catch'](function (reason) {
          _ember['default'].run(function () {
            _this3.send('setMsgHeader', 'error', reason);
          });
        });
        this.send('pageIsDisabled', importRecordAndEdit);
      }
    }
  });
});