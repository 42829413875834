define('frontend/components/category-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    expanded: false,
    addSelectedCategoryString: 'addSelectedCategory',
    removeSelectedCategoryString: 'removeSelectedCategory',
    isExpanded: _ember['default'].computed.equal('expanded', true),

    isSelected: _ember['default'].computed('categoryList.[]', 'category.id', function () {
      return this.get('categoryList').indexOf(this.get('category.id')) !== -1;
    }),

    isRoot: _ember['default'].computed.equal('category.node_type', 'root'),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('expanded');
      },

      addSelectedCategory: function addSelectedCategory(id) {
        this.sendAction('addSelectedCategoryString', id);
      },

      removeSelectedCategory: function removeSelectedCategory(id) {
        this.sendAction('removeSelectedCategoryString', id);
      }
    }
  });
});