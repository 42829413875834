define('frontend/helpers/is-odd', ['exports', 'ember'], function (exports, _ember) {
  exports.isOdd = isOdd;

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  function isOdd(_ref) {
    var _ref2 = _toArray(_ref);

    var i = _ref2[0];

    var args = _ref2.slice(1);

    var _isOdd = i % 2 === 1;
    if (!args.length) {
      return _isOdd;
    }
    if (args.length === 1) {
      return _isOdd ? args[0] : '';
    }
    return _isOdd ? args[0] : args[1];
  }

  exports['default'] = _ember['default'].Helper.helper(isOdd);
});