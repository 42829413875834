define('frontend/controllers/admin/departments/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    queryParams: ['qd'],
    qd: '',
    query: '',
    current: null,
    newEndYear: null,

    modalValid: _ember['default'].computed('current.start_year', 'newEndYear', function () {
      var newEndYear = this.get('newEndYear');
      if (newEndYear > 9999 || newEndYear < 1900) {
        return false;
      }
      if (this.get('current.start_year') > newEndYear) {
        return false;
      }
      // If any other characters than numbers in year
      if (parseInt(newEndYear).toString() !== newEndYear) {
        return false;
      }
      return true;
    }),

    modalInvalidShowInfo: _ember['default'].computed('modalValid', 'newEndYear', function () {
      var newEndYear = this.get('newEndYear');

      if (newEndYear === null) {
        return false;
      }
      if (newEndYear < 1000) {
        return false;
      }
      if (!this.get('modalValid')) {
        return this.get('i18n').t('admin.departments.index.invalidYear');
      }
      return false;
    }),

    actions: {
      sendQuery: function sendQuery() {
        this.set('qd', this.get('query'));
      },
      toggleSetEndYear: function toggleSetEndYear(dep) {
        this.set('current', dep);
        this.set('modalError', null);
        if (dep.end_year) {
          this.set('newEndYear', dep.end_year.toString());
        } else {
          this.set('newEndYear', null);
        }
        _ember['default'].$('#setEndYearModal').modal('show');
      },
      removeEndYear: function removeEndYear(model) {
        this.send('setEndYear', model, null);
      }
    }
  });
});