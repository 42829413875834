define('frontend/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    queryParams: ['lang'],
    lang: null,
    isError: _ember['default'].computed.equal('msgType', 'error'),
    showReviewCount: _ember['default'].computed.gt('userdata.counts.review', 0),
    pageIsDisabled: false,
    currentlyLoading: false,
    isEnglish: _ember['default'].computed('i18n', function () {
      var lang = this.get("i18n.locale");
      if (lang === "en") {
        return true;
      }
      return false;
    }),

    actions: {
      toggleLang: function toggleLang(language) {
        if (language) {
          this.set('i18n.locale', language);
        } else {
          if (this.get('i18n.locale') === 'sv') {
            this.set('i18n.locale', 'en');
            sessionStorage.setItem('lang', 'en');
            this.set("lang", 'en');
          } else {
            this.set('i18n.locale', 'sv');
            sessionStorage.setItem('lang', 'sv');
            this.set("lang", 'sv');
          }
        }
        _ember['default'].run.later(function () {
          location.reload(true);
        });
      }
    }
  });
});