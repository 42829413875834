define('frontend/components/mailer-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    tagName: '',
    setMsgHeaderAction: 'setMessage',

    didRender: function didRender() {
      var _this = this;

      _ember['default'].$('#mailerModal').on('show.bs.modal', function () {
        _this.set('error', null);
        _this.set('hasSuccess', null);
      });
    },

    actions: {
      sendMail: function sendMail() {
        var _this2 = this;

        this.set('error', null);
        this.set('hasSuccess', null);
        var publication = this.get('publication');
        var message = this.get('message');

        var successHandler = function successHandler() {
          _this2.set('hasSuccess', true);
          _this2.set('message', '');
          _ember['default'].$('#mailerModal').modal('hide');
          _this2.sendAction('setMsgHeaderAction', 'success', _this2.get('i18n').t('components.mailerModal.successMessage'));
        };
        var errorHandler = function errorHandler(model) {
          _this2.set('error', model.error.msg);
        };
        var generalHandler = function generalHandler(model) {
          if (model.error) {
            errorHandler(model);
          } else {
            successHandler(model);
          }
        };
        this.store.save('feedback_mail', { message: message, from: 'from_person', publication_id: publication.id }).then(generalHandler);
      }
    }
  });
});