define('frontend/components/review-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    i18n: _ember['default'].inject.service(),
    isApproved: false,

    diffCategories: _ember['default'].computed('item.category_objects', function () {
      var oldCategories = this.get('item.diff_since_review.category_hsv_11.from') || [];
      var newCategories = this.get('item.category_objects');
      var a = [];

      newCategories.forEach(function (cat) {
        if (!oldCategories.findBy('svepid', cat.svepid)) {
          cat.added = true;
        } else {
          cat.same = true;
        }
        a.push(cat);
      });

      oldCategories.forEach(function (cat) {
        if (!newCategories.findBy('svepid', cat.svepid)) {
          cat.removed = true;
          a.push(cat);
        }
      });
      return a;
    }),

    diffDepartments: _ember['default'].computed('item.affiliation', function () {
      var oldDepartments = this.get('item.diff_since_review.affiliation.from') || [];
      var newDepartments = this.get('item.affiliation.departments');
      var a = [];

      newDepartments.forEach(function (dep) {
        if (!oldDepartments.findBy('id', dep.id)) {
          dep.added = true;
        } else {
          dep.same = true;
        }
        a.push(dep);
      });

      oldDepartments.forEach(function (dep) {
        if (!newDepartments.findBy('id', dep.id)) {
          dep.removed = true;
          a.push(dep);
        }
      });
      return a;
    }),

    actions: {
      setMessage: function setMessage(type, msg) {
        this.send('setMsgHeader', type, msg);
      },
      approve: function approve(item) {
        var _this = this;

        this.store.save('review_publication', { id: item.version_id }).then(function () {
          _this.sendAction('setMsgHeader', 'success', _this.get('i18n').t('components.reviewItem.approveSuccess'));
          _this.sendAction('refreshReviewCount');
          _this.set('isApproved', true);
        }, function () {
          _this.sendAction('setMsgHeader', 'error', _this.get('i18n').t('components.reviewItem.approveError'));
        });
      },
      reviewEdit: function reviewEdit(item) {
        this.sendAction('editItem', item, { returnTo: 'publications.dashboard.review' });
      }
    }
  });
});